export const posthogIdentify = (user) => {
    if (window.location.hostname !== "localhost" &&
        window.location.hostname !== "127.0.0.1") {
        window.posthog.identify({
            distinctId: user.uuid,
            properties: {
                name: [user.first_name, user.last_name].join(" "),
                email: user.email,
            },
        });
    }
};
