import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteDBTIntegration,
  getIntegrations,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import { DisplayTable } from "../../Components/Tables";
import { NoIntegration } from "./NoIntegration";
import { GET_INTEGRATIONS } from "./constants";
import classNames from "classnames";
import SidebarModal from "../../Components/SidebarModal";
import { IntegrationDetails } from "./IntegrationDetailsModal";
import { useNavigate, useParams } from "react-router-dom";
import { ColorInsightTags } from "../../Components/Tags";
import { ReactComponent as Trash } from "@/assets/icons/trash.svg";
import { getUser } from "@/services/auth";

export const IntegrationList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dbtCoreIntegrationId, dbtCoreIntegrationEnv } = useParams();
  useEffect(() => {
    if (dbtCoreIntegrationId && dbtCoreIntegrationEnv) return;
    if (!dbtCoreIntegrationId && !dbtCoreIntegrationEnv) return;
    navigate("/settings/integrations");
  }, [dbtCoreIntegrationEnv, dbtCoreIntegrationId, navigate]);

  const { data: integrations, isLoading } = useQuery({
    queryKey: [GET_INTEGRATIONS],
    queryFn: getIntegrations,
  });

  const { mutate } = useMutation(deleteDBTIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_INTEGRATIONS);
    },
  });

  const header = [
    { id: 1, label: "Name of Integration" },
    { id: 2, label: "Type of Integration" },
    { id: 3, label: "Environment Names" },
    { id: 4, label: "Action" },
  ];

  const deleteIntegration = (id) => {
    mutate(id);
  };
  const user = getUser();

  if (isLoading) return <ComponentLoader />;
  return (
    <div className={styles.integrations}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className={styles.title}>Integrations</div>
        <Button
          className={styles.button}
          disabled={!user?.permission?.includes("INTEGRATION_CREATE")}
          onClick={() => navigate("/settings/integrations/new")}
        >
          Add Integration
        </Button>
      </div>
      {integrations.length === 0 ? (
        <NoIntegration
          toggleAddIntegration={() => navigate("/settings/integrations/new")}
        />
      ) : (
        <DisplayTable
          header={header}
          items={integrations}
          templateColumns="4fr 2fr 3fr 2fr"
          rowRender={(i) => {
            return (
              <>
                <div
                  className={classNames(
                    styles.element_overflow,
                    styles.blue_text
                  )}
                >
                  {i.name}
                </div>
                <div className={styles.element_overflow}>
                  {i.type || "dbt core"}
                </div>
                <div className={styles.element_overflow}>
                  <ColorInsightTags
                    tags={i.environments || []}
                    onClick={(item) =>
                      navigate(`/settings/integrations/${i.id}/${item.name}`)
                    }
                    labelFn={(item) => item.name}
                  />
                </div>
                {!i?.is_deleted ? (
                  <div className={classNames(styles.element_overflow)}>
                    <Trash
                      className="cursor-pointer"
                      onClick={() => {
                        deleteIntegration(i.id);
                      }}
                    />
                  </div>
                ) : (
                  <div className={classNames(styles.element_overflow)}>
                    Scheduled for deletion
                  </div>
                )}
              </>
            );
          }}
        />
      )}
      <SidebarModal
        isOpen={dbtCoreIntegrationId && dbtCoreIntegrationEnv}
        toggleModal={() => navigate("/settings/integrations")}
        width="446"
      >
        <IntegrationDetails
          dbtCoreIntegrationId={dbtCoreIntegrationId}
          dbtCoreIntegrationEnv={dbtCoreIntegrationEnv}
        />
      </SidebarModal>
    </div>
  );
};
