import styles from "./styles.module.scss";
import { RiFilter3Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import { ColorTag, Tags } from "../../Components/Tags";
import { FilterDropdown, FilterText } from "../../Components/FilterDropdown";
import { SortIcon } from "../../Components/Tables";
import { formatNumber, Tooltip } from "@/uiCore";
import { useAppState } from "@/modules/app/useAppContext";
import { InfoGray } from "@/assets/icons";

const Icon = ({ children }) => (
  <IconContext.Provider value={{ size: "1.2rem" }}>
    {children}
  </IconContext.Provider>
);

const AUTO_TUNE_MAP = {
  "auto-tune-enabled": { color: "#01CD8C", text: "On", bgColor: "#E6FAF4" },
  "auto-tune-disabled": { color: "#EE140F", text: "Off", bgColor: "#FEE8E7" },
};

const AutoSavingsStatus = ({ autoTuneStatus }) => (
  <div
    style={{
      backgroundColor: AUTO_TUNE_MAP[autoTuneStatus].bgColor,
      color: AUTO_TUNE_MAP[autoTuneStatus].color,
      paddingInline: 6,
      paddingBlock: 2,
    }}
  >
    {AUTO_TUNE_MAP[autoTuneStatus].text}
  </div>
);

const TableFilter = ({ filter, label, resetPage }) => {
  const setChangedState = (currentState) => {
    filter.onChange(currentState);
    resetPage(0);
  };
  return (
    <>
      {filter.filterType === "text" && (
        <FilterText
          label={filter.label}
          onChange={setChangedState}
          value={filter.value}
          placeHolder={filter.placeHolder}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterText>
      )}
      {filter.filterType === "dropdown" && (
        <FilterDropdown
          label={label}
          value={filter.value}
          options={filter.options}
          onChange={setChangedState}
          showSearch={filter.showSearch}
        >
          <Icon>
            <RiFilter3Line />
          </Icon>
        </FilterDropdown>
      )}
    </>
  );
};

const TableHeader = ({ header, resetPage }) => {
  return (
    <div className={styles.table_header}>
      {header.map((h) => (
        <div
          className="w-100 d-flex align-items-center"
          key={h.id}
          {...(h.id === 1 && { style: { width: "200px" } })}
        >
          <span>{h.label}</span>
          <div className="spacer" />
          {h.tooltipInfo && (
            <Tooltip content={h.tooltipInfo} placement="top">
              <InfoGray />
            </Tooltip>
          )}
          {h.filter && (
            <TableFilter
              filter={h.filter}
              label={h.label}
              resetPage={resetPage}
            />
          )}
          {h.sort && (
            <SortIcon sort={h.sort.value} onChange={h.sort.onChange} />
          )}
        </div>
      ))}
    </div>
  );
};

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const WarehouseList = ({ header, warehouses, onRowClick, resetPage }) => {
  const { currency } = useAppState();
  const roundToTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };

  return (
    <div className={styles.table}>
      <TableHeader header={header} resetPage={resetPage} />
      <div className={styles.table_body}>
        {warehouses["items"].map((item) => {
          return (
            <div
              key={`${item.warehouse_rk}_${item.instance_id}`}
              className={styles.table_row}
              onClick={(e) => {
                e.preventDefault();
                onRowClick(item.warehouse_rk);
              }}
            >
              <div className="text-primary condense-text cursor-pointer">
                {item.warehouse_name}
              </div>
              <div className={styles.break_word}>
                {formatNumber(item.total_cost, { currency })}
              </div>
              <div className={styles.break_word}>{item.warehouse_size}</div>
              <div className={styles.break_word}>{item.execution_time_p90}</div>
              <Tags>
                {item.tags.map((tag_rk) => {
                  if (!colorsMap[tag_rk])
                    colorsMap[tag_rk] = colors[i++ % colors.length];
                  return (
                    <ColorTag
                      key={tag_rk}
                      color={colorsMap[tag_rk]}
                      label={tag_rk}
                    />
                  );
                })}
              </Tags>
              <div className={styles.break_word}>
                {roundToTwoDecimalPlaces(item.auto_suspend)} sec
              </div>
              <div className={styles.break_word}>
                {item.median_total_elapsed_time}
              </div>
              <div className={styles.auto_tune}>
                <div className={styles.auto_tune_inner}>
                  {item.is_auto_tune_enabled === "auto-tune-disabled" && (
                    <div className={styles.savings_text}>
                      Estimated (annualized):{" "}
                      <span className={styles.disabled}>
                        {formatNumber(item.estimated_annualized_savings, {
                          currency,
                        })}
                      </span>
                    </div>
                  )}
                  {item.is_auto_tune_enabled === "auto-tune-enabled" && (
                    <>
                      <div className={styles.savings_text}>
                        Realized:{" "}
                        {formatNumber(item.realized_savings, {
                          currency,
                        })}
                      </div>
                      <div className={styles.savings_text}>
                        Annualized:{" "}
                        {formatNumber(item.estimated_annualized_savings, {
                          currency,
                        })}
                      </div>
                    </>
                  )}
                </div>
                <AutoSavingsStatus autoTuneStatus={item.is_auto_tune_enabled} />
              </div>
              <div className={styles.break_word}>
                {item.median_queuing_time}
              </div>
              <div className={styles.break_word}>{item.total_active_time}</div>
              <div className={styles.break_word}>
                <div>{item.idle_time}</div>
                <div>{item.idle_percentage}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { WarehouseList };
