import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Shines2Icon } from "@/assets/icons";
import { IconButton, Stack } from "@uicore";
import classes from "../summary.module.scss";
import { useEffect, useState } from "react";
import { api } from "@/helpers/api_helper";
import { getDateByEndOfDayWithoutTimeoffset, getDateByStartOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import { useSearchParams } from "react-router-dom";
import { ChatTriggerLink, TaskLabels, TeamMatesConfig, } from "@/lib/altimate/altimate-components";
import { useSummaryDispatch } from "../SummaryNew";
import { setShowChatbot } from "../Context/summarySlice";
const SummaryChatButton = ({ chartType, analysisType, costType, savingsType, startDate, endDate, aggregation, isOpen, }) => {
    var _a;
    const dispatch = useSummaryDispatch();
    const [searchParams] = useSearchParams();
    const [helloMessage, setHelloMessage] = useState();
    const chartAnalyserBot = TeamMatesConfig.find((t) => t.key === TaskLabels.ChartBot);
    // Temporary feature flag
    const showSummaryChatButton = isOpen || searchParams.get("chat") === "true";
    const params = {
        start_date: getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: (_a = getDateByEndOfDayWithoutTimeoffset(endDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
        cost_type: costType,
        savings_type: savingsType,
        chart_type: chartType,
        analysis_type: analysisType,
        cost_analysis_type: analysisType === "cost" ? costType : undefined,
        savings_analysis_type: analysisType === "savings" ? savingsType : undefined,
        aggregation,
    };
    useEffect(() => {
        if (!showSummaryChatButton) {
            return;
        }
        api
            .get("/summary/v2/datapilot/initial-questions-chart-chat", params)
            .then((response) => {
            if (!response) {
                return;
            }
            setHelloMessage(_jsxs("div", { children: [_jsxs("p", { className: "ant-default-message", children: ["Hi, I am your ", chartAnalyserBot === null || chartAnalyserBot === void 0 ? void 0 : chartAnalyserBot.name, ". I can help you understand the ", chartType, " ", analysisType, " chart better. Here are few questions that you may be interested in. If not, please use the input below to ask any question about this chart."] }), _jsx(Stack, { direction: "column", children: response.questions.map((question) => (_jsx(ChatTriggerLink, { text: question }, question))) })] }));
        })
            .catch((err) => console.error(err));
    }, [showSummaryChatButton]);
    const openChatbot = () => {
        dispatch(setShowChatbot(Object.assign(Object.assign({}, params), { helloMessage })));
    };
    if (!showSummaryChatButton) {
        return;
    }
    return (_jsx(IconButton, { onClick: openChatbot, title: "Ask questions about this chart", className: classes.chatButton, children: _jsx(Shines2Icon, {}) }));
};
export default SummaryChatButton;
