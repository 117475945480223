import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getQueryTextByHash } from "@/helpers/apis";
import { AsyncPopover } from "@uicore";
import classes from "./styles.module.scss";
import { CodeBlock } from "@components";
import { QUERY_TEXT_API_KEY } from "./constants";
const QueryTextWithHover = ({ queryText, queryHash }) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    return (_jsx(AsyncPopover, { queryKey: QUERY_TEXT_API_KEY, text: queryText, apiFn: () => getQueryTextByHash(queryHash), loadingLabel: "Fetching query text", children: ({ data }) => {
            return (_jsxs("div", { onClick: stopPropagation, children: [_jsx("h3", { children: "Sql text" }), _jsx("div", { children: _jsx(CodeBlock, { code: data.query_text || queryText, language: "sql", fileName: "", className: classes.queryText, showLineNumbers: false, theme: "dark", editorTheme: "tomorrow", titleActions: null }) })] }));
        } }));
};
export default QueryTextWithHover;
