import { formatNumber } from "@/uiCore";
import { BarColors, TotalCostEntities } from "./constants";
import dayjs from "dayjs";
export const getTooltipNameValues = (costType, currency, isFuture) => {
    return [
        {
            name: "",
            value: "date",
            color: "gray",
            formatter: (payload) => dayjs(payload.date).format("DD-MMM-YY"),
        },
        ...TotalCostEntities[costType].fields.map((d) => {
            const k = getKey(d);
            const colorKey = isFuture
                ? `${k}_future`
                : k;
            return {
                name: d.label,
                value: k,
                color: BarColors[colorKey],
                formatter: (payload, item) => tooltipFormatter(payload, item, costType, isPropertyDisabled(d, "hideCurrency") ? "" : currency),
                showSticker: !isPropertyDisabled(d, "hideSticker"),
                showChangePercentage: Boolean(isPropertyDisabled(d, "showChangePercentage")),
            };
        }),
    ];
};
const getSavingsValueOrRange = (payload, value, currency) => {
    if (payload[`min_${value}`] > 0 &&
        payload[`max_${value}`] > 0) {
        const min = formatNumber(payload[`min_${value}`], {
            currency,
        });
        const max = formatNumber(payload[`max_${value}`], {
            currency,
        });
        return `${min} - ${max}`;
    }
    return payload[value] ? formatNumber(payload[value], {
        currency,
    }) : undefined;
};
const tooltipFormatter = (payload, item, costType, currency) => {
    const value = getSavingsValueOrRange(payload, item.value, currency);
    if (!value) {
        return undefined;
    }
    if (costType === "overall") {
        if (item.name === "total") {
            const sum = Number(payload.compute) +
                Number(payload.storage) +
                Number(payload.serverless);
            return `${formatNumber(sum, { currency })}`;
        }
        return value;
    }
    return value;
};
const isPropertyDisabled = (k, property) => {
    if (typeof k === "object" && property in k) {
        return k[property];
    }
    return false;
};
export const getKey = (k, isFuture) => {
    return k.key[isFuture ? "estimate" : "current"];
};
export const formatDate = (date, yearMode) => date ? dayjs(date).format(yearMode ? "MMM-YY" : "DD-MMM-YY") : "";
