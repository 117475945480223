import { useEffect, useMemo, useRef, useState } from "react";
import { CodeSQLEditor } from "../../../Components/CodeEditor";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { addSearchParams, isEmpty } from "../../../helpers/utils";
import { Tab } from "../../../Components/Tab";
import { InsightSection } from "./InsightSection";
import { DebouncedInput, SimpleInput } from "../../../Components/Form";
import { scroll_style } from "../../../Components/ScrollContainer";
import { useSearchParams } from "react-router-dom";
import { Card, CardBody, CodeMarkdown, Stack, Tooltip } from "@uicore";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Close2Icon,
  WarningIcon,
} from "@assets/icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import SingleLineEllipses from "@/uiCore/components/singleLineEllipses/SingleLineEllipses";

const getNavClass = (b) =>
  classNames("icon-sm", b ? "text-black cursor-pointer" : "text-muted");

function getSubstringSpan(query, indices) {
  if (!indices) return null;
  const [start, end] = indices;
  const [startLine, startCol] = start;
  const [endLine, endCol] = end;
  const lines = query.split("\n");

  if (startLine === endLine) {
    return lines[startLine].substring(startCol, endCol);
  }

  const result = [lines[startLine].substring(startCol)];
  for (let i = startLine + 1; i < endLine; i++) {
    result.push(lines[i]);
  }
  result.push(lines[endLine].substring(0, endCol));

  return result.join("\n");
}

function cleanNodeName(str) {
  return str.replace(/_(\d{10,})$/g, "");
}

const ColumnSection = ({ columns }) => {
  const [filteredColumn, setFilteredColumn] = useState([...columns]);

  if (columns.length === 0) {
    return (
      <Stack direction="column">
        <div className="text-muted">No columns</div>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <div className={classNames(styles.card, "mb-0 flex-grow column-section")}>
        <div className="d-flex flex-column gap-sm h-100">
          <div className="d-flex align-items-center gap-xs">
            <div className="fs-5 fw-semibold">Column</div>
            <div className="spacer" />
          </div>
          <SimpleInput
            size="sm"
            placeholder="Search by column name"
            onChange={(v) => {
              const _search = v.toLowerCase();
              setFilteredColumn(
                columns.filter((c) => c.name.toLowerCase().includes(_search))
              );
            }}
          />
          <div className="d-flex align-items-center gap-xs">
            <div>{filteredColumn?.length} columns</div>
          </div>
          <div className="d-flex flex-column gap-sm overflow-y">
            {filteredColumn?.map((_column) => (
              <div
                key={_column.name}
                className={classNames(styles.column_card)}
              >
                <div className="d-flex align-items-center gap-xs">
                  <div>{_column.name}</div>
                  <div className="spacer" />
                </div>
                {_column.expression && (
                  <>
                    <div className="divider" />
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language="sql"
                      wrapLines={true}
                      wrapLongLines={true}
                      PreTag="div"
                    >
                      {_column.expression}
                    </SyntaxHighlighter>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export function SideContent({
  selectedNode,
  query,
  sqlLineage,
  parentEditorRef,
  selectedTab,
  closeSideContent,
  opportunities,
  startDate,
  endDate,
}) {
  const editorRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const setSelectedInsightId = (v) =>
    setSearchParams(addSearchParams("opportunityId", v), { replace: true });
  const opportunityId = searchParams.get("opportunityId");
  const selectedOpportunity = opportunities?.find(
    (o) => o.opportunity_id === opportunityId
  );
  const _columns = useMemo(() => {
    return (
      sqlLineage?.details?.[selectedNode]?.columns?.map((item) => ({
        ...item,
        description: item.expression,
      })) || []
    );
  }, [selectedNode, sqlLineage?.details]);

  const tabs = [
    {
      label: "Insights",
      component: (
        <InsightSection
          sideContentMode={true}
          opportunities={opportunities}
          selectedOpportunityId={opportunityId}
          setOpportunityId={(item) => {
            const opportunity = opportunities.find(
              (o) => o.opportunity_id === item
            );
            if (opportunity?.id) setSelectedInsightId(opportunity?.id);
          }}
          startDate={startDate}
          endDate={endDate}
          explanationEndpoint="/opportunities/explanation"
        />
      ),
    },
    {
      label: `Columns (${_columns.length})`,
      component: <ColumnSection columns={_columns} />,
    },
  ];

  useEffect(() => {
    if (selectedTab !== "Code") return;
    parentEditorRef.current?.removeHighlight();
    const indices = selectedOpportunity?.parent_indices;
    if (!indices) return;
    parentEditorRef.current?.setHighlight(indices.map((index) => ({ index })));
    parentEditorRef.current?.highlightSpan({ index: indices });
  }, [parentEditorRef, selectedOpportunity?.parent_indices, selectedTab]);

  useEffect(() => {
    const highlightCode = (indices) => {
      editorRef.current?.removeHighlight();
      editorRef.current?.setHighlight(indices.map((index) => ({ index })));
      editorRef.current?.highlightSpan({ index: indices[0] });
    };

    const indices = selectedNode
      ? sqlLineage.details[selectedNode].indices
      : selectedOpportunity?.parent_indices;
    editorRef.current?.removeHighlight();
    if (indices) {
      setTimeout(() => highlightCode([indices]), 100);
    }
  }, [
    opportunities,
    opportunityId,
    selectedNode,
    selectedOpportunity?.parent_indices,
    sqlLineage,
  ]);

  return (
    <Card className="mb-0">
      <CardBody>
        <Stack direction="column">
          <div className="d-flex">
            {selectedNode ? (
              <SingleLineEllipses content={cleanNodeName(selectedNode)} />
            ) : null}
            <div className="spacer" />
            <Close2Icon onClick={closeSideContent} className="cursor-pointer" />
          </div>
          {query && sqlLineage?.is_lineage && (
            <CodeSQLEditor
              value={query}
              height="28vh"
              ref={editorRef}
              lineNumbers
            />
          )}
          {selectedOpportunity?.opportunity_name && (
            <div className="br-2 bg-grey">
              <div className="d-flex align-items-center p-2">
                <div className="fs-5 fw-semibold">
                  Details: "{selectedOpportunity?.opportunity_name}"
                </div>
                <div className="spacer" />
              </div>
              <div className="divider" />
              <div className="bg-white p-3 br-2 m-2">
                <div className="d-flex align-items-center">
                  <div className="fs-4 fw-500">Problem</div>
                  <div className="spacer" />
                  {!selectedOpportunity?.parent_indices && (
                    <Tooltip
                      content={
                        "Query mapping incomplete: The opportunity details may reference expanded " +
                        "views or temporary objects not present in the original query. Please review " +
                        "the full query execution plan for more context."
                      }
                      placement="top"
                    >
                      <div className="icon-xs d-flex align-items-center">
                        <WarningIcon />
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.markdown_description}>
                  <CodeMarkdown
                    value={
                      selectedOpportunity?.markdown_description ||
                      selectedOpportunity?.description ||
                      selectedOpportunity?.reason
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <Tab tabs={tabs} pagedTab={false} />
        </Stack>
      </CardBody>
    </Card>
  );
}
