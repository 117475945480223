import { CodeSQLEditor } from "../../Components/CodeEditor";
import styles from "./styles.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getQueryGroupDetail,
  getQueryGroupLatestQuery,
} from "../../helpers/backend_helper";
import { Feedback } from "../../Components/Feedback";
import { Alert, Button, Card, CardBody, Input } from "reactstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  QueryExplanation,
  InsightSection,
  SideContent,
  QuerySqlLineage,
} from "./components";
import classNames from "classnames";
import QueryInsightsRobot from "@/assets/images/query_insights_robot.png";
import Typewriter from "typewriter-effect";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/arrow_left.svg";
import { TypeWriterLoader } from "@/Components/Loader";
import { getQueryGroupSqlLineageById } from "@/helpers/apis";
import * as Sentry from "@sentry/browser";
import { QueryListWithFilter } from "./Query";
import { Paginate } from "../../Components/Paginate";
import { TableHeader } from "./QueryList";
import {
  getAllQueryGroupCSVFile,
  getAllQueryGroups,
  getQueryFilters,
  updateQueryGroupName,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import {
  GET_ALL_QUERY_GROUPS,
  GET_QUERY_FILTERS,
  GET_QUERY_GROUP_BY_ID,
  QUERY_TEXT_API_KEY,
  QueryGroupTabs,
} from "./constants";
import {
  ColorInsightTags,
  ColorTag,
  FilterTagWrapper,
  Tags,
} from "../../Components/Tags";
import SidebarModal from "../../Components/SidebarModal";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/datetime.svg";
import { useCopilot } from "../Copilot";
import { useCopilotActions } from "../Copilot/useCopilotActions";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { DateRange, StaticDateRangeKeys } from "../../Components/DateRange";
import dayjs from "dayjs";
import QueryTextWithHover from "./QueryText";
import InsightLabel from "./InsightLabel";
import { GhostTab, Tab } from "../../Components/Tab/Tab";
import { QueryGroup } from "./types";
import { getQueryTextByHash } from "@/helpers/apis";
import { formatNumber, Select } from "@/uiCore";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";
import { useAppState } from "@/modules/app/useAppContext";

const GroupSelectedHeader = ({
  queryHash,
  trimmedQuery,
  metrics,
  close,
  tags,
  query_name,
}) => {
  const [isNameEditing, setIsNameEditing] = useState(false);
  const { currency } = useAppState();
  const queryClient = useQueryClient();
  const [groupName, setGroupName] = useState(trimmedQuery);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (data) => updateQueryGroupName(queryHash, data),
    onSuccess: (data) => {
      if (data.ok) {
        setIsNameEditing(false);
        queryClient.invalidateQueries({ queryKey: [GET_QUERY_GROUP_BY_ID] });
      }
    },
  });
  return (
    <div className="bg-blue-light p-3 border-radius-top d-flex align-items-start gap-sm">
      <div
        className="p-1 br-2 bg-blue text-white cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/query/groups");
        }}
      >
        <ArrowLeftIcon className="icon-sm" />
      </div>
      <div className="d-flex flex-column gap-sm w-100">
        <div className="d-flex gap-xs align-items-center">
          {!isNameEditing ? (
            <>
              <div className="text-primary">{groupName}</div>
              <EditIcon
                className={classNames("cursor-pointer", styles.edit_icon, {
                  [styles.active]: isNameEditing,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNameEditing(!isNameEditing);
                }}
              />
            </>
          ) : (
            <>
              <Input
                disabled={!isNameEditing}
                rows={1}
                className={styles.name_input}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder={trimmedQuery}
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  mutate({ group_name: groupName });
                }}
              >
                Save
              </Button>
              <Button outline onClick={(e) => setIsNameEditing(false)}>
                Cancel
              </Button>
            </>
          )}
          <div className="spacer" />
          {/* <Button
            size="sm"
            onClick={() =>
              navigate("/experimentation/new", {
                state: { queryHash, name: query_name },
              })
            }
          >
            Start experiments
          </Button> */}
        </div>
        <div className="d-flex flex-wrap">
          {metrics.map((item) => (
            <div key={item.label} className="me-4">
              <span className="text-muted">{item.label}: </span>
              {item.label === "Average cost" ||
              item.label === "Total cost" ||
              item.label === "Annualized cost" ? (
                <span>{formatNumber(item.value, { currency })}</span>
              ) : (
                <span>{item.value}</span>
              )}
            </div>
          ))}
        </div>
        <ColorInsightTags tags={tags} />
      </div>
    </div>
  );
};

const GroupTabs = ({
  selectedGroup,
  sqlLineage,
  queryHash,
  query,
  parentEditorRef,
  showSideContent,
  closeSideContent,
  setSelectedNode,
  selectedInsight,
  opportunityId,
  setOpportunityId,
}) => {
  const [selectedTab, setSelectedTab] = useState(QueryGroupTabs.Opportunities);
  const [selectedOpportunityTab, setSelectedOpportunityTab] =
    useState("sql-visualizer");
  const editorRef = useRef(null);

  const navigate = useNavigate();
  const { startDate, endDate, onUpdate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );
  const tabs = [];
  if (sqlLineage && sqlLineage?.is_lineage) {
    tabs.push({
      label: "SQL Visualizer",
      value: "sql-visualizer",
      betaMode: true,
    });
  }
  tabs.push({ label: "Code", value: "code" });

  const onTabChange = (value) => {
    setSelectedOpportunityTab(value);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={`bg-white pt-4 ${styles.groupTabs}`}>
      <Tab
        onSelectedTabChange={(v) => {
          setSelectedTab(v);
          if (v === QueryGroupTabs.Queries) closeSideContent();
        }}
        tabs={[{ label: "Opportunities" }, { label: "Queries" }]}
      />

      {selectedTab === QueryGroupTabs.Opportunities ? (
        <div className="p-2 pt-0">
          <div className="d-inline-block mb-3">
            <GhostTab
              setSelectedTab={onTabChange}
              tabs={tabs}
              selectedTab={selectedOpportunityTab}
            />
          </div>
          <div className="d-flex flex-column gap-sm">
            {selectedOpportunityTab === "sql-visualizer" ? (
              <QuerySqlLineage
                sqlLineage={sqlLineage}
                selectedInsight={selectedInsight}
                showSideContent={showSideContent}
                onNodeSelect={(node) => {
                  setSelectedNode(node);
                  const _opportunityId =
                    sqlLineage?.query_tags_meta?.[node]?.[0]?.opportunity_id ||
                    "";
                  setOpportunityId(_opportunityId);
                }}
              />
            ) : null}

            {selectedOpportunityTab === "code" && (
              <CodeSQLEditor
                ref={parentEditorRef}
                value={query.query_text}
                height="36vh"
                lineNumbers={true}
                minimap
              />
            )}
            <QueryExplanation queryHash={queryHash} />
            <div>
              <div className="d-flex m-2">
                <div className="fs-4">Opportunities</div>
                <div className="spacer" />
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  availableStaticRanges={[
                    "Last day",
                    "Last 7 days",
                    "Last 28 days",
                  ]}
                  disableCalendarSelection
                  onDateRangeSelect={onUpdate}
                />
              </div>
              <InsightSection
                opportunities={sqlLineage?.tags}
                totalRuns={selectedGroup.total_run}
                selectedOpportunityId={opportunityId}
                setOpportunityId={(item) => {
                  setOpportunityId(item);
                  if (!sqlLineage) return;
                  parentEditorRef.current?.onParentResize();
                  const insightId = sqlLineage?.tags?.find(
                    (t) => t.opportunity_id === item
                  )?.id;
                  const _selectNode = (key) => {
                    setSelectedNode(key);
                    document.dispatchEvent(
                      new CustomEvent("onSelectedNodes", { detail: key })
                    );
                  };
                  if (!insightId) return _selectNode("");
                  for (const key in sqlLineage.query_tags_meta) {
                    for (const t of sqlLineage.query_tags_meta[key] || []) {
                      if (insightId === t.id) return _selectNode(key);
                    }
                  }
                  _selectNode("");
                }}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      ) : null}
      {selectedTab === QueryGroupTabs.Queries ? (
        <QueryListWithFilter
          setSelectedQuery={(v) =>
            navigate(
              `/query/${v}?from=/query/groups/${selectedGroup.query_hash}`
            )
          }
          queryHash={selectedGroup.query_hash}
        />
      ) : null}
    </div>
  );
};

const MainContent = ({
  groupDetails,
  queryHash,
  query,
  selectedInsight,
  sqlLineage,
  setSelectedNode,
  parentEditorRef,
  setSelectedTab,
  showSideContent,
  closeSideContent,
}) => {
  const [fromPage, setFromPage] = useState("/query/groups");
  const location = useLocation();
  useEffect(() => {
    if (location.state?.from) setFromPage(location.state.from);
  }, []);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunityId = searchParams.get("opportunityId");
  const setOpportunityId = (v) =>
    setSearchParams(addSearchParams("opportunityId", v), { replace: true });

  useEffect(() => {
    if (!opportunityId) return;
    for (const key in sqlLineage?.query_tags_meta || []) {
      for (const t of sqlLineage?.query_tags_meta[key] || []) {
        if (selectedInsight === t.tag_rk) {
          setSelectedNode(key);
          document.dispatchEvent(
            new CustomEvent("onSelectedNodes", { detail: key })
          );
          return;
        }
      }
    }
  }, []);

  const queryFilters = {
    tags: searchParams.getAll("tags") || [],
    executionTime: searchParams.get("executionTime"),
    queryCost: searchParams.get("queryCost"),
    warehouses: searchParams.getAll("warehouses") || [],
    users: searchParams.getAll("users") || [],
    queryHash: searchParams.get("queryHash") || "",
    queryParameterizedHash: searchParams.get("queryParameterizedHash") || "",
    sessionID: searchParams.get("sessionID") || "",
  };

  return (
    <div className={classNames("d-flex flex-column", styles.query_details)}>
      <GroupSelectedHeader
        trimmedQuery={groupDetails.trimmed_query}
        tags={groupDetails.tags}
        metrics={[
          { label: "Total run", value: groupDetails.total_run },
          {
            label: "Average execution time",
            value: groupDetails.avg_exec_time,
          },
          {
            label: "Average cost",
            value: groupDetails.avg_cost,
          },
          {
            label: "Total cost",
            value: groupDetails.total_cost,
          },
          {
            label: "Annualized cost",
            value: groupDetails.annualized_cost,
          },
          {
            label: "Distinct warehouses",
            value: groupDetails.distinct_warehouses,
          },
          { label: "Distinct users", value: groupDetails.distinct_users },
        ]}
        queryHash={groupDetails.query_hash}
        query_name={groupDetails.query_name}
      />
      <GroupTabs
        selectedGroup={groupDetails}
        sqlLineage={sqlLineage}
        queryHash={groupDetails.query_hash}
        query={query}
        parentEditorRef={parentEditorRef}
        opportunityId={opportunityId}
        setOpportunityId={setOpportunityId}
        closeSideContent={closeSideContent}
        setSelectedNode={setSelectedNode}
        selectedInsight={selectedInsight}
        showSideContent={showSideContent}
      />

      <div className="divider w-100 mb-2" />
      <Feedback feedback_id={queryHash} feedback_type="query_tag" />
    </div>
  );
};

const GroupDetails = () => {
  const { queryHash } = useParams();

  const editorRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedTab, setSelectedTab] = useState("SQL Visualizer");
  const [searchParams, setSearchParams] = useSearchParams();

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const { data: querydetails, isLoading } = useQuery({
    queryKey: ["GET_QUERY_GROUP_LATEST_QUERY", queryHash],
    queryFn: () => getQueryGroupLatestQuery(queryHash),
  });
  const { data: sqlLineage, isLoading: isSqlLineageLoading } = useQuery({
    queryKey: [
      "GET_QUERY_GROUP_SQL_LINEAGE_BY_ID",
      queryHash,
      startDate,
      endDate,
    ],
    queryFn: () =>
      getQueryGroupSqlLineageById(queryHash, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
    retry: false,
    onError: (err) => {
      if (err === "canceled") {
        Sentry.captureException(new Error("SQL Lineage timeout"));
      }
    },
  });
  const {
    data: groupDetails,
    isLoading: isGrouploading,
    error,
  } = useQuery({
    queryKey: [GET_QUERY_GROUP_BY_ID, queryHash, startDate, endDate],
    queryFn: () =>
      getQueryGroupDetail(queryHash, {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
      }),
  });

  const opportunityId = searchParams.get("opportunityId");
  const selectedInsight = sqlLineage?.tags?.find(
    (t) => t.id === opportunityId
  )?.tag_rk;
  const selectedNodeOpportunities = selectedNode
    ? sqlLineage?.query_tags_meta?.[selectedNode]
    : null;

  const selectedOpportunity = sqlLineage?.tags?.find(
    (o) => o.opportunity_id === opportunityId
  );
  const closeSideContent = () => {
    setSearchParams(removeSearchParams("opportunityId"), { replace: true });
    setSelectedNode("");
  };

  if (isLoading || isSqlLineageLoading || isGrouploading) {
    return (
      <div className="h-100 align-content-center">
        <TypeWriterLoader
          label="Analyzing query might take a few seconds"
          loadingTexts={[
            "Looking at query metadata",
            "Analyzing query pattern",
            "Fetching query insights",
          ]}
        />
      </div>
    );
  }
  const { query } = querydetails;

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  if (!groupDetails) {
    return <Alert color="danger">No data for query group found</Alert>;
  }

  const showSideContent = selectedNode || opportunityId;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: showSideContent ? "2fr 1fr" : "1fr",
        gap: "1rem",
      }}
    >
      <MainContent
        queryHash={queryHash}
        groupDetails={groupDetails}
        parentEditorRef={editorRef}
        query={query}
        selectedInsight={selectedInsight}
        setSelectedNode={setSelectedNode}
        sqlLineage={sqlLineage}
        setSelectedTab={setSelectedTab}
        closeSideContent={closeSideContent}
        showSideContent={showSideContent}
      />
      {showSideContent && (
        <SideContent
          selectedNode={selectedNode}
          query={query.query_text}
          sqlLineage={sqlLineage}
          parentEditorRef={editorRef}
          selectedTab={selectedTab}
          closeSideContent={closeSideContent}
          opportunities={
            selectedNodeOpportunities ||
            (selectedOpportunity ? [selectedOpportunity] : [])
          }
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};

export { GroupDetails };
