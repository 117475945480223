import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { SqlLineage } from "../../../Components/Lineage";
import { getMoneySavings } from "@/pages/Explorer/utils";
import { useAppState } from "@/modules/app/useAppContext";
import classNames from "classnames";
import styles from "../styles.module.scss";
import { InfoIcon } from "@/assets/icons";
const QuerySqlLineage = ({ sqlLineage, selectedInsight, showSideContent, onNodeSelect, }) => {
    const { currency } = useAppState();
    useEffect(() => {
        var _a, _b;
        if (!sqlLineage)
            return;
        const { query_tags_meta } = sqlLineage;
        if (selectedInsight) {
            const insightNodes = [];
            const nodesSavingsPerformance = {};
            for (const key in query_tags_meta) {
                for (const t of query_tags_meta[key] || []) {
                    if (t.tag_rk !== selectedInsight)
                        continue;
                    insightNodes.push(key);
                    if (t.cost_savings_ui || t.time_savings_ui) {
                        nodesSavingsPerformance[key] = nodesSavingsPerformance[key] || {};
                    }
                    if (t.cost_savings_ui) {
                        nodesSavingsPerformance[key]["savings"] = {
                            percent: (_a = t === null || t === void 0 ? void 0 : t.cost_savings_ui) === null || _a === void 0 ? void 0 : _a.percent,
                            value: getMoneySavings(t.cost_savings_min, t.cost_savings_max, currency),
                            tooltip: (_b = t === null || t === void 0 ? void 0 : t.cost_savings_ui) === null || _b === void 0 ? void 0 : _b.tooltip,
                        };
                    }
                    if (t.time_savings_ui) {
                        nodesSavingsPerformance[key]["performance"] = t.time_savings_ui;
                    }
                }
            }
            document.dispatchEvent(new CustomEvent("onHighlightedNodes", { detail: insightNodes }));
            document.dispatchEvent(new CustomEvent("onNodesSavingsPerformance", {
                detail: nodesSavingsPerformance,
            }));
        }
        else {
            const nodesCost = {};
            for (const key in sqlLineage.details) {
                if (sqlLineage.details[key].node_cost)
                    nodesCost[key] = sqlLineage.details[key].node_cost;
            }
            document.dispatchEvent(new CustomEvent("onNodesCost", { detail: nodesCost }));
        }
    }, [selectedInsight, sqlLineage]);
    useEffect(() => {
        document.dispatchEvent(new CustomEvent("fitView"));
    }, [showSideContent]);
    useEffect(() => {
        const handleSelectedNode = (e) => {
            const node = e.detail;
            onNodeSelect(node);
            document.dispatchEvent(new CustomEvent("onSelectedNodes", { detail: node }));
        };
        // @ts-ignore
        document.addEventListener("selectedNode", handleSelectedNode);
        return () => {
            // @ts-ignore
            document.removeEventListener("selectedNode", handleSelectedNode);
        };
    }, []);
    if (!sqlLineage)
        return null;
    return (_jsxs("div", { className: "position-relative", children: [(sqlLineage === null || sqlLineage === void 0 ? void 0 : sqlLineage.is_error) && (_jsxs("div", { className: classNames("position-absolute d-flex gap-sm align-items-center", styles.sql_lineage_error_overlay), children: [_jsx(InfoIcon, {}), _jsx("div", { children: "Simplified view shown. Query contains unsupported keywords or constructs." })] })), _jsx(SqlLineage, Object.assign({}, sqlLineage, { height: "50vh", externalSidePanel: true }))] }));
};
export { QuerySqlLineage };
