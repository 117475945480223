import { CopyIconButton } from "../../Components/CopyButton";
import styles from "./styles.module.scss";
import { ReactComponent as InsightIcon } from "@/assets/icons/insight.svg";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getSummaryGraph,
  getWarehouse,
  getWarehouseAutoSavings,
  getWarehouseAutoSavingsAuditEvents,
  getWarehouseAutoSavingsData,
  getWarehouseAutoSavingsDataV2,
  getWarehouseDecisionData,
  getWarehouseQueryExecutionTimes,
  toggleWarehouseAutoSavings,
} from "../../helpers/backend_helper";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
import classNames from "classnames";
import {
  GET_WAREHOUSE_AUTO_SAVINGS,
  GET_WAREHOUSE_AUTO_SAVINGS_AUDIT_EVENTS,
  GET_WAREHOUSE_AUTO_SAVINGS_CONFIG,
  GET_WAREHOUSE_BY_ID,
  GET_WAREHOUSE_DECISION_DATA,
  GET_WAREHOUSE_QUERY_EXECUTION_TIME,
} from "./constants";
import { Graph } from "./Graph";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BackButton } from "../../Components/Button";
import { GhostTab, Tab } from "../../Components/Tab";
import { Dropdown } from "../../Components/Form";
import { ReactComponent as ArrowDown } from "@/assets/icons/arrow_down.svg";
import { ReactComponent as Wand } from "@/assets/icons/wand.svg";
import PlusIcon from "@/assets/icons/plus.svg";
import ThunderIcon from "@/assets/icons/thunder.svg";
import ThunderDisabledIcon from "@/assets/icons/thunder_disabled.svg";
import EnterpriseIcon from "@/assets/icons/enterprise_icon.svg";
import { ReactComponent as NoAutoSaveImage } from "@/assets/images/no_auto_save.svg";
import { AutoManageGraph } from "./AutoManageGraph";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import dayjs from "dayjs";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { CustomTooltip } from "../../Components/Graph/misc";
import { EmptyScreen } from "./misc";
import datastore_processing from "../../assets/images/datastore_processing.png";
import { Slider } from "../../Components/Slider/Slider";
import { Paginate } from "../../Components/Paginate";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { InsightSection } from "../Query/components";
import { formatNumber, Select, Stack } from "@uicore";
import { GET_OPPORTUNITY_RESOURCE_DATA } from "@/services/constants";
import { DateRange } from "@/Components/DateRange";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import {
  useDateFromSearchParams,
  useResourceOpportunities,
} from "@/helpers/useTimeHooks";
import { useAppState } from "@/modules/app/useAppContext";
import { ResourceType } from "../Explorer/types";
import SingleLineEllipses from "@/uiCore/components/singleLineEllipses/SingleLineEllipses";
import { Close2Icon, SnowflakeQueryIcon } from "@/assets/icons";
import { WarehouseQueries } from "./WarehouseQueries";
import { ColorInsightTags } from "@/Components/Tags";

const roundToTwoDecimalPlaces = (number) => {
  return Math.round(number * 100) / 100;
};

const NoAutoSave = ({ estimatedSavings }) => {
  const { currency } = useAppState();
  console.log("estimatedSavings", estimatedSavings);
  return (
    <div className={styles.no_auto_save}>
      <NoAutoSaveImage />
      <div className={styles.side_content}>
        <div className={styles.saving}>
          {formatNumber(estimatedSavings, { currency })}{" "}
          <div className={styles.text}>per year</div>
        </div>
        <div className="text-muted">
          You are throwing away per year down the drain by not enabling
          auto-management of the warehouse.
        </div>
      </div>
    </div>
  );
};

const PERIOD_OPTIONS = [
  { label: "Week", value: 7 },
  { label: "Last 30 Days", value: 30 },
];

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      {payload.value}s
    </text>
  </g>
);

const CustomYAxisBytesTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      {payload.value} GB
    </text>
  </g>
);

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {dayjs(payload.value).format("DD-MMM-YY")}
      </text>
    </g>
  );
};

const DecisionGraph = ({ data }) => {
  const stackedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      decisions: roundToTwoDecimalPlaces(entry.decisions),
    }));
  }, [data]);

  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="Start tracking soon"
        subTitle="Auto tune decisions is not started yet. Our datapilot will start tracking
      soon and you can see the progress"
      />
    );

  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <LineChart
        data={stackedData}
        margin={{
          top: 50,
          right: 50,
          left: 50,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tick={<CustomXAxisTick />} />
        <YAxis
          label={{
            value: "No. of Decisions",
            fill: "black",
            angle: 0,
            position: "insideTopLeft",
            offset: -30,
          }}
        />
        <Tooltip />
        <Line
          type="stepBefore"
          dataKey="decisions"
          stroke="#247EFE"
          dot={{ strokeWidth: 2, fill: "#247EFE" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ExecutionTimeGraph = ({ data }) => {
  const [executionTimeFilter, setExecutionTImeFilter] = useState("all");

  const handleLegendClick = (data) => {
    const datakey = data?.value;

    switch (datakey) {
      case "Avg Execution Time":
        if (executionTimeFilter === "execution_time") {
          setExecutionTImeFilter("all");
        } else {
          setExecutionTImeFilter("execution_time");
        }
        break;
      case "P90 Execution Time":
        if (executionTimeFilter === "p90_execution_time") {
          setExecutionTImeFilter("all");
        } else {
          setExecutionTImeFilter("p90_execution_time");
        }
        break;
      default:
        setExecutionTImeFilter("all");
    }
  };

  const stackedData = useMemo(() => {
    if (data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      execution_time: roundToTwoDecimalPlaces(entry.execution_time),
      execution_time_ui: entry.execution_time_ui,
      p90_execution_time: roundToTwoDecimalPlaces(entry.p90_execution_time),
      p90_execution_time_ui: entry.p90_execution_time_ui,
      p90_execution_time_stack: roundToTwoDecimalPlaces(
        entry.p90_execution_time - entry.execution_time
      ),
    }));
  }, [data]);

  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="No Execution data found"
        subTitle="No execution data found for the selected period. You will see the data once queries are executed."
      />
    );

  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <AreaChart
        data={stackedData}
        margin={{
          top: 50,
          right: 30,
          left: 50,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tick={<CustomXAxisTick />} />
        <YAxis
          label={{
            value: "Query Execution Time",
            fill: "black",
            angle: 0,
            position: "insideTopLeft",
            offset: -30,
          }}
          tick={<CustomYAxisTick />}
        />
        <Tooltip
          content={
            <CustomTooltip
              nameValue={[
                { name: "Date", value: "time", color: "gray" },
                {
                  name: "Avg Execution Time",
                  value: "execution_time_ui",
                  color: "#247EFE",
                },
                {
                  name: "P90 Execution Time",
                  value: "p90_execution_time_ui",
                  color: "#01CD8C",
                },
              ]}
            />
          }
        />
        <Area
          type="linear"
          name="Avg Execution Time"
          dataKey={
            executionTimeFilter === "all" ||
            executionTimeFilter === "execution_time"
              ? "execution_time"
              : "execution_time_hidden"
          }
          stroke="#53B1FD"
          fill="#53B1FD"
          dot={{
            fill: "#C0D7F9",
            strokeWidth: "1px",
            stroke: "#247EFE",
          }}
          stackId={1}
        />
        <Area
          type="linear"
          name="P90 Execution Time"
          dataKey={
            executionTimeFilter === "all" ||
            executionTimeFilter === "p90_execution_time"
              ? "p90_execution_time"
              : "p90_execution_time_hidden"
          }
          stroke="#52c7a2"
          fill="#52c7a2"
          dot={{
            fill: "#52c7a2",
            strokeWidth: "1px",
            stroke: "#52c7a2",
          }}
          stackId={1}
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          wrapperStyle={{ bottom: -20 }}
          onClick={handleLegendClick}
          formatter={(value, entry, index) => (
            <span className={styles.legend}>{value}</span>
          )}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const BytesScannedGraph = ({ data }) => {
  const stackedData = useMemo(() => {
    if (data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      bytes_scanned: roundToTwoDecimalPlaces(
        entry.bytes_scanned / 1024 / 1024 / 1024
      ),
      bytes_scanned_ui: entry.bytes_scanned_ui,
    }));
  }, [data]);

  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="No Bytes Scanned data found"
        subTitle="No bytes scanned data found for the selected period. You will see the data once queries are executed."
      />
    );

  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <AreaChart
        data={stackedData}
        margin={{
          top: 50,
          right: 30,
          left: 50,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tick={<CustomXAxisTick />} />
        <YAxis
          label={{
            value: "Total Bytes Scanned",
            fill: "black",
            angle: 0,
            position: "insideTopLeft",
            offset: -30,
          }}
          tick={<CustomYAxisBytesTick />}
        />
        <Tooltip
          content={
            <CustomTooltip
              nameValue={[
                { name: "Date", value: "time", color: "gray" },
                {
                  name: "Total bytes scanned",
                  value: "bytes_scanned_ui",
                  color: "#247EFE",
                },
              ]}
            />
          }
        />
        <Area
          type="linear"
          dataKey="bytes_scanned"
          stroke="#247EFE66"
          fill="#247EFE66"
          dot={{
            fill: "#C0D7F9",
            strokeWidth: "1px",
            stroke: "#247EFE",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const OverloadTimeGraph = ({ data }) => {
  const [overloadTimeFilter, setOverloadTimeFilter] = useState("all");

  const handleLegendClick = (data) => {
    const datakey = data?.value;

    switch (datakey) {
      case "Avg Queuing Time":
        if (overloadTimeFilter === "overload_time") {
          setOverloadTimeFilter("all");
        } else {
          setOverloadTimeFilter("overload_time");
        }
        break;
      case "P90 Queuing Time":
        if (overloadTimeFilter === "p90_overload_time") {
          setOverloadTimeFilter("all");
        } else {
          setOverloadTimeFilter("p90_overload_time");
        }
        break;
      default:
        setOverloadTimeFilter("all");
    }
  };

  const stackedData = useMemo(() => {
    if (data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      overload_time: roundToTwoDecimalPlaces(entry.avg_queued_overload_time),
      overload_time_ui: entry.avg_queued_overload_time_ui,
      p90_overload_time: roundToTwoDecimalPlaces(
        entry.p90_queued_overload_time
      ),
      p90_overload_time_ui: entry.p90_queued_overload_time_ui,
      p90_overload_time_stack: roundToTwoDecimalPlaces(
        entry.p90_queued_overload_time - entry.avg_queued_overload_time
      ),
    }));
  }, [data]);

  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="No Queuing time data found"
        subTitle="No Queuing time data found for the selected period. You will see the data once queries are executed."
      />
    );

  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <AreaChart
        data={stackedData}
        margin={{
          top: 50,
          right: 30,
          left: 50,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tick={<CustomXAxisTick />} />
        <YAxis
          label={{
            value: "Query Queuing Time",
            fill: "black",
            angle: 0,
            position: "insideTopLeft",
            offset: -30,
          }}
          tick={<CustomYAxisTick />}
        />
        <Tooltip
          content={
            <CustomTooltip
              nameValue={[
                { name: "Date", value: "time", color: "gray" },
                {
                  name: "Avg Queuing Time",
                  value: "overload_time_ui",
                  color: "#247EFE",
                },
                {
                  name: "P90 Queuing Time",
                  value: "p90_overload_time_ui",
                  color: "#01CD8C",
                },
              ]}
            />
          }
        />
        <Area
          type="linear"
          name="Avg Queuing Time"
          dataKey={
            overloadTimeFilter === "all" ||
            overloadTimeFilter === "overload_time"
              ? "overload_time"
              : "overload_time_hidden"
          }
          stroke="#53B1FD"
          fill="#53B1FD"
          dot={{
            fill: "#C0D7F9",
            strokeWidth: "1px",
            stroke: "#247EFE",
          }}
          stackId={1}
        />
        <Area
          type="linear"
          name="P90 Queuing Time"
          dataKey={
            overloadTimeFilter === "all" ||
            overloadTimeFilter === "p90_overload_time"
              ? "p90_overload_time"
              : "p90_overload_time_hidden"
          }
          stroke="#52c7a2"
          fill="#52c7a2"
          dot={{
            fill: "#52c7a2",
            strokeWidth: "1px",
            stroke: "#52c7a2",
          }}
          stackId={1}
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          wrapperStyle={{ bottom: -20 }}
          onClick={handleLegendClick}
          formatter={(value, entry, index) => (
            <span className={styles.legend}>{value}</span>
          )}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const QueryCountGraph = ({ data }) => {
  const stackedData = useMemo(() => {
    if (data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      query_count: entry.query_count,
      query_count_ui: entry.query_count,
    }));
  }, [data]);

  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="No Query Count data found"
        subTitle="No query count data found for the selected period. You will see the data once queries are executed."
      />
    );

  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <AreaChart
        data={stackedData}
        margin={{
          top: 50,
          right: 30,
          left: 50,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tick={<CustomXAxisTick />} />
        <YAxis
          label={{
            value: "Total Query Count",
            fill: "black",
            angle: 0,
            position: "insideTopLeft",
            offset: -30,
          }}
        />
        <Tooltip
          content={
            <CustomTooltip
              nameValue={[
                { name: "Date", value: "time", color: "gray" },
                {
                  name: "Total Query Count",
                  value: "query_count_ui",
                  color: "#247EFE",
                },
              ]}
            />
          }
        />
        <Area
          type="linear"
          dataKey="query_count"
          stroke="#247EFE66"
          fill="#247EFE66"
          dot={{
            fill: "#C0D7F9",
            strokeWidth: "1px",
            stroke: "#247EFE",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const ChartTile = ({ warehouse_rk, graphType, setGraphType }) => {
  const [period, setPeriod] = useState({
    decision: 7,
    execution_time: 7,
  });
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "week"));
  const [endDate, setEndDate] = useState(dayjs());

  const {
    data: query_execution_time_graph_data,
    isLoading: isLoadingQueryExecutionTimeData,
  } = useQuery({
    queryKey: [
      GET_WAREHOUSE_QUERY_EXECUTION_TIME,
      warehouse_rk,
      period?.execution_time,
    ],
    queryFn: () =>
      getWarehouseQueryExecutionTimes(warehouse_rk, {
        period: period?.execution_time,
      }),
    enabled:
      graphType === "execution_time" ||
      graphType === "bytes_scanned" ||
      graphType === "overload_time" ||
      graphType === "query_count",
  });

  const { data: decision_graph_data, isLoading: isLoadingDecisionData } =
    useQuery({
      queryKey: [GET_WAREHOUSE_DECISION_DATA, warehouse_rk, period?.decision],
      queryFn: () =>
        getWarehouseDecisionData(warehouse_rk, { period: period?.decision }),
      enabled: graphType === "decision",
    });

  // The useEffect below does two things:
  // 1. It checks if the graph type is decision and if the decision graph data is available
  //    If the decision graph data is available, it sets the start and end date based on the data
  // 2. It checks if the graph type is execution time and if the query execution time graph data is available
  //    If the query execution time graph data is available, it sets the start and end date based on the data
  useEffect(() => {
    if (
      graphType === "decision" &&
      decision_graph_data?.decision_chart &&
      decision_graph_data?.decision_chart.length > 0
    ) {
      if (decision_graph_data?.decision_chart.length > 0) {
        const dates = decision_graph_data?.decision_chart.map(
          (item) => item.date
        );
        const parsedDates = dates.map((date) => dayjs(date, "DD MMM. YYYY"));
        parsedDates.sort((a, b) => a - b);
        const minDate = parsedDates[0];
        const maxDate = parsedDates[parsedDates.length - 1];
        setStartDate(minDate);
        setEndDate(maxDate);
      }
      return;
    }
    if (
      (graphType === "execution_time" || graphType === "bytes_scanned") &&
      query_execution_time_graph_data?.query_execution_time &&
      query_execution_time_graph_data?.query_execution_time.length > 0
    ) {
      if (query_execution_time_graph_data?.query_execution_time.length > 0) {
        const dates = query_execution_time_graph_data?.query_execution_time.map(
          (item) => item.date
        );
        const parsedDates = dates.map((date) => dayjs(date, "DD MMM. YYYY"));
        parsedDates.sort((a, b) => a - b);
        const minDate = parsedDates[0];
        const maxDate = parsedDates[parsedDates.length - 1];
        setStartDate(minDate);
        setEndDate(maxDate);
      }
    }
  }, [
    decision_graph_data,
    graphType,
    period?.decision,
    period?.bytes_scanned,
    period?.execution_time,
    query_execution_time_graph_data,
  ]);

  const currentPeriod =
    graphType === "decision" ? period?.decision : period?.execution_time;
  const yearMode =
    PERIOD_OPTIONS.find((x) => x.value === currentPeriod)?.label === "Year";

  const dateFormat = yearMode ? "MMM 'YY" : "DD MMM 'YY";

  return (
    <div>
      <div className="d-flex mb-2 gap-sm align-items-center"></div>
      <div className="d-flex mb-2 gap-sm align-items-center">
        <GhostTab
          tabs={[
            { label: "Agent Decisions", value: "decision" },
            {
              label: (
                <div className="d-flex align-items-center gap-xs">
                  <SnowflakeQueryIcon />
                  Query Execution Time
                </div>
              ),
              value: "execution_time",
            },
            {
              label: (
                <div className="d-flex align-items-center gap-xs">
                  <SnowflakeQueryIcon />
                  Total Bytes Scanned
                </div>
              ),
              value: "bytes_scanned",
            },
            {
              label: (
                <div className="d-flex align-items-center gap-xs">
                  <SnowflakeQueryIcon />
                  Query Queuing time
                </div>
              ),
              value: "overload_time",
            },
            {
              label: (
                <div className="d-flex align-items-center gap-xs">
                  <SnowflakeQueryIcon />
                  Query Count
                </div>
              ),
              value: "query_count",
            },
          ]}
          selectedTab={graphType}
          setSelectedTab={setGraphType}
        />
        <div className="spacer" />
        <div className="text-muted">
          {startDate.format(dateFormat)}
          {" - "}
          {endDate.format(dateFormat)}
        </div>
        <Dropdown
          id="details-graph-period"
          options={PERIOD_OPTIONS}
          onChange={(value) => {
            if (graphType === "decision") {
              setPeriod({ ...period, decision: value });
            } else {
              setPeriod({ ...period, execution_time: value });
            }
          }}
          value={currentPeriod}
          popoverProps={{ placement: "bottom-end", offset: [5, 5] }}
        >
          <div className={styles.chart_duration}>
            {PERIOD_OPTIONS.find((x) => x.value === currentPeriod)?.label}
            <ArrowDownIcon />
          </div>
        </Dropdown>
      </div>
      {graphType === "decision" &&
        (isLoadingDecisionData ? (
          <RelativeComponentLoader componentHeight={40} />
        ) : (
          <DecisionGraph data={decision_graph_data.decision_chart} />
        ))}
      {graphType === "execution_time" &&
        (isLoadingQueryExecutionTimeData ? (
          <RelativeComponentLoader componentHeight={40} />
        ) : (
          <ExecutionTimeGraph
            data={query_execution_time_graph_data.query_execution_time}
          />
        ))}
      {graphType === "bytes_scanned" &&
        (isLoadingQueryExecutionTimeData ? (
          <RelativeComponentLoader componentHeight={40} />
        ) : (
          <BytesScannedGraph
            data={query_execution_time_graph_data.query_execution_time}
          />
        ))}
      {graphType === "overload_time" &&
        (isLoadingQueryExecutionTimeData ? (
          <RelativeComponentLoader componentHeight={40} />
        ) : (
          <OverloadTimeGraph
            data={query_execution_time_graph_data.query_execution_time}
          />
        ))}
      {graphType === "query_count" &&
        (isLoadingQueryExecutionTimeData ? (
          <RelativeComponentLoader componentHeight={40} />
        ) : (
          <QueryCountGraph
            data={query_execution_time_graph_data.query_execution_time}
          />
        ))}
    </div>
  );
};

const SelectorDropdown = ({
  id = "aggregation-selector",
  label = "Aggregate",
  options,
  method,
  setMethod,
}) => {
  return (
    <Select
      id={id}
      onChange={setMethod}
      value={method}
      options={options.map((o) => ({
        label: <div>{o.label}</div>,
        value: o.value,
      }))}
      label={label}
      showDivider
    />
  );
};

const AutoManageSection = ({
  data,
  isLoading,
  startDate,
  endDate,
  autoManageStatus,
  warehouseRK,
  estimatedSavings,
  aggregation,
  setAggregation,
}) => {
  const timeFrames = [
    { label: "Week", value: 7 },
    { label: "Last 30 Days", value: 30 },
  ];

  const AggregationOptions = [
    { label: "Daily", value: "day" },
    { label: "Weekly", value: "week" },
    { label: "Monthly", value: "month" },
  ];

  if (data?.graph_data && data?.graph_data.length === 0)
    return (
      <EmptyScreen
        title="Graph not available"
        subTitle="No data available for the selected period."
      />
    );

  return (
    <div className="d-flex flex-column gap-md">
      {!autoManageStatus && <NoAutoSave estimatedSavings={estimatedSavings} />}
      {isLoading && <RelativeComponentLoader componentHeight={40} />}
      {data && (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between">
            <h4>Auto Tune Savings</h4>
            <div className="d-flex align-items-center gap-sm">
              <SelectorDropdown
                options={AggregationOptions}
                method={aggregation}
                setMethod={setAggregation}
              />
            </div>
          </div>
          <div className={styles.warehouse_graph}>
            <AutoManageGraph
              topInfo={data?.top_info}
              data={data?.graph_data}
              showPeriodDropdown={false}
              defaultTimeFrame={timeFrames}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const AUTO_MANAGE_LEVEL_OPTIONS = [
  { label: "Cost Optimized", value: "cost" },
  { label: "Balanced (Coming Soon)", value: "balanced" },
  { label: "Performance (Coming Soon)", value: "performance" },
];

const timeline_contentStyle = {
  fontFamily: "Lexend",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "15px",
  color: "#082247",
  display: "flex",
  alignItems: "center",
  marginLeft: "240px",
  padding: "14px",
  gap: "10px",
  border: "1px solid #E7E8EA",
  boxShadow: "none",
  borderRadius: "12px",
};

const iconStyle = {
  width: "40px",
  height: "40px",
  display: "flex",
  background: "white",
  boxShadow: "none",
  border: "1px solid #E7E8EA",
  padding: "5px",
  marginTop: "10px",
};

const TimelineEventIconWithDate = (eventType, eventTime) => {
  const isoDatetime = new Date(eventTime).toISOString();
  const formattedDatetime = dayjs(isoDatetime).format("hh:mm A, MMM DD, YYYY");
  switch (eventType) {
    case "auto_tune_enabled":
      return (
        <>
          <img src={ThunderIcon} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    case "auto_tune_disabled":
      return (
        <>
          <img src={ThunderDisabledIcon} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    default:
      return (
        <>
          <img src={EnterpriseIcon} alt="" />
          <div className={styles.timeline_event_icon}>{formattedDatetime}</div>
        </>
      );
    // code
  }
};

const TimeFrameSelectorDropdown = ({ options, period, setPeriod }) => {
  const getLabelByPeriod = (period) => {
    return options.find((p) => p.value === period).label;
  };

  return (
    <Dropdown
      id="history-period-dropdown"
      onChange={setPeriod}
      value={period}
      options={options.map((p) => ({
        label: <div>{p.label}</div>,
        value: p.value,
      }))}
      showDivider
    >
      <div className={styles.graph_dropdown}>
        <div className={styles.graph_label}>{getLabelByPeriod(period)}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  );
};

const DecisionTypeSelectorDropdown = ({ options, period, setPeriod }) => {
  const getLabelByPeriod = (period) => {
    return options.find((p) => p.value === period).label;
  };

  return (
    <Dropdown
      id="history-period-dropdown"
      onChange={setPeriod}
      value={period}
      options={options.map((p) => ({
        label: <div>{p.label}</div>,
        value: p.value,
      }))}
      showDivider
    >
      <div className={styles.graph_dropdown}>
        <div className={styles.graph_label}>{getLabelByPeriod(period)}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  );
};

const AutoTuneHistory = ({ warehouseRK }) => {
  const timeframes = [
    { label: "Day", value: 1 },
    { label: "Week", value: 7 },
    { label: "Last 30 Days", value: 30 },
  ];
  const [period, setPeriod] = useState(30);
  const [page, setPage] = useState(1);
  const { data: autoTuneHistory, isLoading: autoTuneHistoryLoading } = useQuery(
    {
      queryKey: [
        GET_WAREHOUSE_AUTO_SAVINGS_AUDIT_EVENTS,
        warehouseRK,
        period,
        page,
      ],
      queryFn: () =>
        getWarehouseAutoSavingsAuditEvents(warehouseRK, {
          period: period,
          page: page,
          size: 5,
        }),
    }
  );

  // const decisionTypes = [
  //   { label: "All", value: "all" },
  //   { label: "add", value: "add" },
  //   { label: "delete", value: "delete" },
  // ];

  // const [decisionType, setDecisionType] = useState("all");
  if (autoTuneHistoryLoading)
    return <RelativeComponentLoader componentHeight={40} />;

  return (
    <div className={styles.auto_tune_history}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.title}>Auto tune decision history</div>
        <div className="d-flex align-items-center gap-sm">
          <div className="text-muted">
            {dayjs().subtract(period, "days").format("DD MMM 'YY")}
            {" - "}
            {dayjs().format("DD MMM 'YY")}
          </div>
          <div className={styles.timeframe_inside_graph}>
            <TimeFrameSelectorDropdown
              options={timeframes}
              period={period}
              setPeriod={setPeriod}
            />
          </div>
        </div>
      </div>
      {autoTuneHistory &&
        (autoTuneHistory.total === 0 ? (
          <EmptyScreen
            title="No Audit Events yet"
            subTitle="No Decisions found for the selected period. You will see the data once decisions are made."
          />
        ) : (
          <>
            <VerticalTimeline
              className={styles.vertical_timeline}
              layout={"1-column-left"}
              lineColor={"#E7E8EA"}
            >
              {autoTuneHistory?.items.map((event) => (
                <VerticalTimelineElement
                  key={event.rk}
                  className={styles.vertical_timeline_element}
                  contentStyle={timeline_contentStyle}
                  contentArrowStyle={{
                    display: "none",
                  }}
                  iconStyle={iconStyle}
                  icon={TimelineEventIconWithDate(
                    event.event_type,
                    event.change_timestamp
                  )}
                >
                  {event.event_message}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
            <Paginate
              itemCount={autoTuneHistory.total}
              page={page - 1}
              pageSize={autoTuneHistory.size}
              numPages={autoTuneHistory.pages}
              onPageClick={(page) => setPage(page + 1)}
            />
          </>
        ))}
    </div>
  );
};

const MainContent = ({ warehouse_rk, warehouseDetails, opportunities }) => {
  const { currencySymbol } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOpportunityId = searchParams.get("opportunityId");
  const setSelectedOpportunityId = (v) =>
    setSearchParams(addSearchParams("opportunityId", v), { replace: true });

  const [aggregation, setAggregation] = useState("day");

  const [autoManageStatus, setAutoManageStatus] = useState(false);
  const [autoManageLevel, setAutoManageLevel] = useState("");
  const [graphType, setGraphType] = useState("decision");
  const [toggleAutoTuneError, setToggleAutoTuneError] = useState("");
  const navigate = useNavigate();
  const [realizedSavings, setRealizedSavings] = useState({
    min_value: 0,
    max_value: 0,
  });
  const [estimatedSavings, setEstimatedSavings] = useState(0);

  const { startDate, endDate, onUpdate } = useDateFromSearchParams(
    dayjs().subtract(1, "month").toDate(),
    dayjs().toDate(),
    "chart"
  );

  const {
    startDate: opportunityStartDate,
    endDate: opportunityEndDate,
    onUpdate: onOpportunityUpdate,
  } = useDateFromSearchParams();

  useQuery({
    queryKey: [GET_WAREHOUSE_AUTO_SAVINGS_CONFIG, warehouse_rk],
    queryFn: () => getWarehouseAutoSavings(warehouse_rk),
    onSuccess: (data) => {
      setAutoManageStatus(data.is_auto_tune_enabled);
    },
  });

  const queryClient = useQueryClient();
  const autoSavingConfigMutate = useMutation(
    () => toggleWarehouseAutoSavings(warehouse_rk),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WAREHOUSE_AUTO_SAVINGS_CONFIG);
      },
      onError: (data) => {
        setToggleAutoTuneError(data);
        setTimeout(() => {
          setToggleAutoTuneError("");
        }, 10000);
      },
    }
  );

  const { data: autoTuneData, isLoading: isAutoTuneLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_AUTO_SAVINGS, startDate, endDate, aggregation],
    queryFn: () =>
      getWarehouseAutoSavingsDataV2(warehouse_rk, {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        aggregation: aggregation,
      }),
    onSuccess: (data) => {
      setRealizedSavings(data?.top_info?.realized_saving);
      setEstimatedSavings(data?.top_info?.estimated_annual_savings?.value);
    },
  });

  const tabs = [
    {
      label: "Opportunities",
      component: (
        <div>
          <Graph
            warehouse_rk={warehouse_rk}
            startDate={startDate}
            endDate={endDate}
          />
          <div>
            <div className="d-flex m-2">
              <div className="fs-4">Opportunities</div>
              <div className="spacer" />
              <DateRange
                startDate={opportunityStartDate}
                endDate={opportunityEndDate}
                availableStaticRanges={[
                  "Last day",
                  "Last 7 days",
                  "Last 28 days",
                ]}
                disableCalendarSelection
                onDateRangeSelect={onOpportunityUpdate}
              />
            </div>

            <InsightSection
              opportunities={opportunities}
              selectedOpportunityId={selectedOpportunityId}
              setOpportunityId={setSelectedOpportunityId}
              startDate={opportunityStartDate}
              endDate={opportunityEndDate}
            />
          </div>
        </div>
      ),
    },
    {
      label: "Auto Tune",
      component: (
        <AutoManageSection
          data={autoTuneData}
          isLoading={isAutoTuneLoading}
          startDate={startDate}
          endDate={endDate}
          autoManageStatus={autoManageStatus}
          warehouseRK={warehouse_rk}
          estimatedSavings={estimatedSavings}
          aggregation={aggregation}
          setAggregation={setAggregation}
        />
      ),
    },
    {
      label: "Queries",
      component: (
        <WarehouseQueries
          startDate={opportunityStartDate}
          endDate={opportunityEndDate}
          warehouse={warehouseDetails.warehouse_name}
        />
      ),
    },
  ];

  // search tab will give the index of the tab to be selected
  const searchTab = searchParams.get("tab") || 0;
  const selectedSearchTab = tabs.find(
    (tab, index) => Number(index) === Number(searchTab)
  );
  const [selectedTab, setSelectedTab] = useState(selectedSearchTab?.label);

  const handleSliderChange = (index) => {
    setAutoManageLevel(AUTO_MANAGE_LEVEL_OPTIONS[index].value);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className={styles.warehouse_details}>
      <div className="d-flex gap-sm mb-3">
        <div className={styles.back_button}>
          <BackButton onClick={() => navigate("/warehouse")} />
        </div>
        <div className="d-flex flex-column ms-2 me-2 gap-sm w-100">
          <div className="d-flex align-items-center">
            <div className="fs-4 me-2">Warehouse Name:</div>
            <div className="fs-4 text-primary me-2">
              {warehouseDetails.warehouse_name}
            </div>
            <CopyIconButton
              value={warehouseDetails.warehouse_name}
              color="#247EFE"
            />
          </div>
          <div className="d-flex gap-md flex-wrap">
            <div className="d-flex">
              <div className={styles.title}>{"Scaling Policy : "}</div>
              <div className={styles.value}>
                {warehouseDetails.scaling_policy}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>{"Clusters (Min/Max) : "}</div>
              <div className={styles.value}>
                {warehouseDetails.min_cluster_count}/
                {warehouseDetails.max_cluster_count}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>{"Auto-Resume : "}</div>
              <div className={styles.value}>
                {warehouseDetails.auto_resume ? "Enabled" : "Disabled"}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>{"Size : "}</div>
              <div className={styles.value}>
                {warehouseDetails.warehouse_size}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>{"Query Acceleration : "}</div>
              <div className={styles.value}>
                {warehouseDetails.enable_query_acceleration === "true"
                  ? "Enabled"
                  : "Disabled"}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>
                {"Query Acceleration Scale Factor : "}
              </div>
              <div className={styles.value}>
                {warehouseDetails.query_acceleration_max_scale_factor}
              </div>
            </div>
            <div className="d-flex">
              <div className={styles.title}>{"Warehouse Type : "}</div>
              <div className={styles.value}>
                {warehouseDetails.warehouse_type}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-md">
              <div className="d-flex gap-sm align-items-center">
                <div className="d-flex gap-sm align-items-center">
                  <div>Auto Tune Level</div>
                  <Slider
                    data={AUTO_MANAGE_LEVEL_OPTIONS.map((x) => x.label)}
                    handleChange={handleSliderChange}
                    currentValue={autoManageLevel}
                  />
                </div>
                {autoManageStatus && (
                  <>
                    <div>Realized savings:</div>
                    <div className={styles.estimated_saving_item}>
                      <span>
                        {realizedSavings?.min_value ===
                        realizedSavings?.max_value
                          ? `${currencySymbol}${roundToTwoDecimalPlaces(
                              realizedSavings?.min_value
                            ).toLocaleString()}`
                          : `${currencySymbol}${roundToTwoDecimalPlaces(
                              realizedSavings.min_value
                            ).toLocaleString()} - ${currencySymbol}${roundToTwoDecimalPlaces(
                              realizedSavings.max_value
                            ).toLocaleString()}`}
                      </span>
                    </div>
                  </>
                )}
                <div>Estimated Annual Savings:</div>
                <div className={styles.estimated_saving_item}>
                  <span>
                    {currencySymbol}
                    {roundToTwoDecimalPlaces(estimatedSavings).toLocaleString()}
                  </span>
                </div>
                {toggleAutoTuneError !== "" && (
                  <div className={styles.on_toggle_failure}>
                    {toggleAutoTuneError}
                  </div>
                )}
                {autoSavingConfigMutate.isLoading && (
                  <div className={styles.on_toggle_loading}>
                    <img
                      src={datastore_processing}
                      className={styles.toggle_loading}
                      alt="test_connection"
                    />
                    <>
                      Turning {autoManageStatus ? "off" : "on"} auto tune
                      savings.
                    </>
                  </div>
                )}
              </div>
            </div>
            <div
              className={styles.auto_manage_status}
              style={{
                backgroundColor: autoManageStatus ? "#247efe" : "#B8BFCA",
              }}
            >
              <Label className="mb-0" for="auto-manage-status-switch">
                Auto Tune Status
              </Label>
              <FormGroup switch className="mb-0">
                <Input
                  id="auto-manage-status-switch"
                  type="switch"
                  checked={autoManageStatus}
                  onChange={() => autoSavingConfigMutate.mutate()}
                  disabled={autoSavingConfigMutate.isLoading}
                />
              </FormGroup>
            </div>
          </div>
          <ColorInsightTags
            tags={(warehouseDetails.tags || []).map((i) => i.name)}
          />
        </div>
      </div>

      <Card>
        <CardBody>
          <div className={styles.graphTabs}>
            <div className={styles.dateRange}>
              <DateRange
                startDate={startDate}
                endDate={endDate}
                showTimezone={true}
                onDateRangeSelect={onUpdate}
              />
            </div>
            <Tab
              tabs={tabs}
              defaultTab={0}
              onSelectedTabChange={handleTabChange}
            />
          </div>
        </CardBody>
      </Card>

      {selectedTab === "Auto Tune" && autoSavingConfigMutate.isLoading && (
        <RelativeComponentLoader />
      )}

      {selectedTab === "Auto Tune" && autoManageStatus && (
        <>
          <Card>
            <CardBody>
              <ChartTile
                graphType={graphType}
                setGraphType={setGraphType}
                warehouse_rk={warehouse_rk}
              />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <AutoTuneHistory warehouseRK={warehouse_rk} />
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

function SideContent({ opportunities, closeSideContent }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );
  const selectedOpportunityId = searchParams.get("opportunityId");
  const setSelectedOpportunityId = (v) =>
    setSearchParams(addSearchParams("opportunityId", v), { replace: true });

  const selectedOpportunity = (opportunities || []).find(
    (o) => o.opportunity_id === selectedOpportunityId
  );

  return (
    <Card className="mb-0">
      <CardBody>
        <Stack direction="column">
          <div className="d-flex align-items-center">
            {selectedOpportunity && (
              <SingleLineEllipses
                content={selectedOpportunity?.opportunity_name}
                className="fs-5 fw-semibold"
              />
            )}
            <div className="spacer" />
            <Close2Icon onClick={closeSideContent} className="cursor-pointer" />
          </div>
          <InsightSection
            opportunities={opportunities}
            sideContentMode={true}
            selectedOpportunityId={selectedOpportunityId}
            setOpportunityId={setSelectedOpportunityId}
            startDate={startDate}
            endDate={endDate}
            explanationEndpoint="/opportunities/explanation"
          />
        </Stack>
      </CardBody>
    </Card>
  );
}

const WarehouseDetails = () => {
  const { warehouse_rk } = useParams();
  const { startDate, endDate } = useDateFromSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOpportunityId = searchParams.get("opportunityId");

  const numDays = dayjs().diff(dayjs(startDate), "day");
  // const period = useMemo(() => {
  //   if (numDays <= 1) return 1;
  //   if (numDays <= 7) return 7;
  //   else return 30;
  // }, [numDays]);
  const period = 30;

  const getParams = () => {
    const params = {};
    if (period) params.period = 30;
    return params;
  };

  const warehouseDetails = useQuery({
    queryKey: [GET_WAREHOUSE_BY_ID, warehouse_rk, period],
    queryFn: () => getWarehouse(warehouse_rk, getParams()),
  });

  const opportunities = useResourceOpportunities(
    warehouseDetails?.data?.warehouse_name,
    ResourceType.Warehouse
  );

  const closeSideContent = () => {
    setSearchParams(removeSearchParams("opportunityId"), { replace: true });
  };

  if (opportunities.isLoading || warehouseDetails.isLoading) {
    return <RelativeComponentLoader />;
  }

  if (warehouseDetails.error) {
    return <Alert color="danger">{warehouseDetails.error}</Alert>;
  }

  const selectedOpportunity = opportunities?.data?.data.find(
    (o) => o.opportunity_id === selectedOpportunityId
  );

  return (
    <div
      style={{
        display: "grid",
        // have to use percentages because the chart doesn't shrink
        // when sidepanel is open
        gridTemplateColumns: selectedOpportunityId ? "66% 33%" : "99%",
        gap: "1rem",
      }}
    >
      <MainContent
        warehouse_rk={warehouse_rk}
        warehouseDetails={warehouseDetails.data}
        opportunities={opportunities?.data?.data}
      />
      {selectedOpportunity && (
        <SideContent
          opportunities={[selectedOpportunity]}
          closeSideContent={closeSideContent}
        />
      )}
    </div>
  );
};

export { WarehouseDetails };
