var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, Suspense, useEffect } from "react";
import { RelativeComponentLoader } from "@/Components/Loader";
import classes from "../summary.module.scss";
import { api, generateStream } from "@/helpers/api_helper";
import { useIntercom } from "react-use-intercom";
import useSummaryState from "../useSummaryState";
import { TeamMatesConfig } from "@/lib/altimate/altimate-components";
import { TaskLabels } from "@/lib/altimate/altimate-components";
import { useAppDispatch } from "@/modules/app/useAppContext";
import { setSidebarExpanded } from "@/modules/app/appSlice";
import { IconButton } from "@/uiCore";
import { CloseIcon } from "@/assets/icons";
import { useSummaryDispatch } from "../SummaryNew";
import { setShowChatbot } from "../Context/summarySlice";
const Chatbot = lazy(() => import("@/modules/chatbot/Chatbot"));
const SummaryChatbot = () => {
    const { update } = useIntercom();
    const { showChatbot } = useSummaryState();
    const appDispatch = useAppDispatch();
    const summaryDispatch = useSummaryDispatch();
    const showSummaryChatBot = showChatbot !== undefined;
    useEffect(() => {
        if (showSummaryChatBot) {
            update({ hideDefaultLauncher: true });
            appDispatch(setSidebarExpanded(false));
        }
        else {
            update({ hideDefaultLauncher: false });
            appDispatch(setSidebarExpanded(true));
        }
    }, [showSummaryChatBot]);
    const handleFollowupRequest = (sessionId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield api.get("/summary/v2/datapilot/follow-up-questions-chart-chat", {
                session_id: sessionId,
            });
            return response === null || response === void 0 ? void 0 : response.questions;
        }
        catch (error) {
            console.error(error);
            return [];
        }
    });
    const onRequest = (messages, sessionId, onStatusUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        const userMessages = messages.filter((message) => message.role === "user");
        const lastMessage = userMessages[userMessages.length - 1];
        if (!lastMessage.content) {
            return;
        }
        return new Promise((resolve) => {
            generateStream("/summary/v2/datapilot/start-chart-chat", (chunk) => {
                try {
                    console.log("processing chunk", chunk);
                    const status = JSON.parse(chunk);
                    if (status.type === "info")
                        onStatusUpdate(status);
                    if (status.type === "agent_outcome") {
                        if (status.citations) {
                            onStatusUpdate({ type: "citations", citations: status.citations, message: "" });
                        }
                        resolve(status.message);
                    }
                }
                catch (err) {
                    console.warn("error while parsing stream onprogress", err);
                }
            }, Object.assign(Object.assign({ session_id: sessionId }, params), { user_query: lastMessage.content }));
        });
    });
    if (!showSummaryChatBot) {
        return;
    }
    const { helloMessage } = showChatbot, params = __rest(showChatbot, ["helloMessage"]);
    const chartAnalyserBot = TeamMatesConfig.find((t) => t.key === TaskLabels.ChartBot);
    const assistantMeta = chartAnalyserBot
        ? { avatar: chartAnalyserBot.avatar, name: chartAnalyserBot.name }
        : undefined;
    const closeSummaryChatbot = () => {
        summaryDispatch(setShowChatbot(undefined));
    };
    return (_jsx("div", { className: classes.summaryChatbot, children: _jsxs(Suspense, { fallback: _jsx(RelativeComponentLoader, { label: "Loading Chatbot" }), children: [_jsx(IconButton, { className: classes.closeBtn, onClick: closeSummaryChatbot, children: _jsx(CloseIcon, {}) }), _jsx(Chatbot, { onRequest: onRequest, feedback_id: `summary-${Date.now()}`, feedback_type: `summary`, onFollowupRequest: handleFollowupRequest, helloMessage: helloMessage, assistantMeta: assistantMeta })] }) }));
};
export default SummaryChatbot;
