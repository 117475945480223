import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/arrow_left.svg";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Lineage, SqlLineage } from "../../Components/Lineage";
import { Tab } from "../../Components/Tab";
import { useQuery } from "react-query";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
import { isLineage } from "../../helpers/tenant";
import { Schema } from "./Schema";
import {
  downstreamTablesDBT,
  getDBTModelByRk,
  getSQLLineage,
  postColumnsDBT,
  postConnectedColumnsDBT,
  startingNodeDBT,
  upstreamTablesDBT,
} from "../../helpers/backend_helper";
import { GET_DBT_MODEL_BY_ID, GET_SQL_LINEAGE_BY_ID } from "./constants";
import { CodeDiffEditor } from "@/Components/CodeEditor";
import CodeSQLEditor from "../../Components/CodeEditor/SQLCodeEditor";
import { useSession } from "../../helpers/hooks";
import { NoCompiledSql, NoCompiledSqlTest } from "./NoDbtModels";

const DbtCode = ({ rawCode, compiledCode }) => {
  return (
    <CodeDiffEditor
      height="68vh"
      editor1Value={rawCode}
      editor2Value={compiledCode}
      title1="Raw Code"
      title2="Compiled Code"
      language="sql"
      readOnly
      lineNumbers
      minimap
    />
  );
};

const SQLLineage = ({
  dbtModelId,
  compiledCode,
  modelName,
  integrationId,
  integrationEnvironmentId,
  resourceType,
}) => {
  const session_id = useSession();
  const { data, isLoading } = useQuery({
    queryKey: [GET_SQL_LINEAGE_BY_ID, dbtModelId],
    queryFn: async () => {
      let { tableEdges, details, nodePositions } = await getSQLLineage({
        dbt_model_rk: dbtModelId,
        session_id,
      });
      const FINAL_SELECT = "__final_select__";
      const replaceFinalSelect = (t) => (t === FINAL_SELECT ? modelName : t);
      tableEdges = tableEdges.map((e) => e.map(replaceFinalSelect));
      details[modelName] = details[FINAL_SELECT];
      delete details[FINAL_SELECT];
      if (nodePositions) {
        nodePositions[modelName] = nodePositions[FINAL_SELECT];
        delete nodePositions[FINAL_SELECT];
      }
      return { tableEdges, details, nodePositions };
    },
  });

  if (!compiledCode && resourceType === "test") {
    return (
      <NoCompiledSqlTest
        modelName={modelName}
        integrationId={integrationId}
        integrationEnvironmentId={integrationEnvironmentId}
      />
    );
  }

  if (!compiledCode) {
    return (
      <NoCompiledSql
        modelName={modelName}
        integrationId={integrationId}
        integrationEnvironmentId={integrationEnvironmentId}
      />
    );
  }

  if (isLoading) return <RelativeComponentLoader />;
  return (
    <div className="row">
      <div className="col-md">
        <SqlLineage {...data} height="76vh" />
      </div>
      <div className="col-md">
        <CodeSQLEditor value={compiledCode} height="76vh" lineNumbers />
      </div>
    </div>
  );
};

const DbtModelsDetails = () => {
  let { dbtModelId } = useParams();
  dbtModelId = atob(dbtModelId);
  const navigate = useNavigate();
  const { state } = useLocation();
  const lineage = isLineage();
  const { data: dbtModel, isLoading: isTableLoading } = useQuery({
    queryKey: [GET_DBT_MODEL_BY_ID, dbtModelId],
    queryFn: () => getDBTModelByRk({ dbt_model_rk: dbtModelId }),
  });

  if (isTableLoading) {
    return <ComponentLoader />;
  }
  if (!dbtModel) return <Navigate to="/dbt_models" />;

  const tabs = [
    {
      label: "Schema",
      component: <Schema table={dbtModel} />,
    },
    {
      label: "Lineage",
      component: (
        <Lineage
          tableId={dbtModelId}
          getStartingNode={startingNodeDBT}
          postColumns={postColumnsDBT}
          upstreamTables={upstreamTablesDBT}
          downstreamTables={downstreamTablesDBT}
          postConnectedColumns={postConnectedColumnsDBT}
          height="100%"
        />
      ),
      disabled: !lineage,
    },
    {
      label: "Code",
      component: (
        <DbtCode
          rawCode={dbtModel.raw_code}
          compiledCode={dbtModel.compiled_code}
        />
      ),
    },
  ];

  const resourceType = dbtModel?.resource_type;
  if (!(resourceType === "seed" || resourceType === "source")) {
    tabs.push({
      label: "SQL Visualizer",
      betaMode: true,
      component: (
        <SQLLineage
          integrationId={dbtModel.dbt_core_integration_id}
          integrationEnvironmentId={
            dbtModel.dbt_core_integration_environment_id
          }
          resourceType={resourceType}
          dbtModelId={dbtModelId}
          compiledCode={dbtModel?.compiled_code}
          modelName={dbtModel.model_name}
        />
      ),
    });
  }

  const handleBackButtonClick = () => {
    if (state?.from === "dbt_models") {
      navigate(-1);
    } else {
      navigate("/dbt_models");
    }
  };

  return (
    <div className={styles.dbt_model_details}>
      <div className="d-flex align-items-start">
        <div
          className={styles.navigate_datasets}
          onClick={handleBackButtonClick}
        >
          <ArrowLeftIcon />
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex mb-2">
            <div className="text-primary fs-5 text-overflow">
              {dbtModel.model_name}
            </div>
          </div>
        </div>
        <div className="spacer" />
      </div>
      <Card className="h-100">
        <CardBody className="h-100">
          <Tab tabs={tabs} fullscreen={true} />
        </CardBody>
      </Card>
    </div>
  );
};

export { DbtModelsDetails };
