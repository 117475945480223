import { z } from "zod";
export const tenantInfoSchema = z.object({
    currency: z.string().default("USD"),
    frontendUrl: z.string(),
    teammatesEnabled: z.boolean(),
});
export const urlResponse = z.object({
    url: z.string(),
});
export const GetOpportunitiesWarehouseFilterResponseSchema = z.object({
    label: z.string(),
    value: z.string(),
});
