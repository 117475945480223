var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardTitle, Dropdown, Stack, Tabs } from "@uicore";
import { useEffect, useState } from "react";
import classes from "../summary.module.scss";
import { generateStream } from "@/helpers/api_helper";
import dayjs from "dayjs";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { getDateByEndOfDayWithoutTimeoffset, getDateByStartOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import { ComponentLoader } from "@/Components/Loader";
const DatapilotAnalysis = () => {
    var _a;
    const [isLoading, setIsLoading] = useState(false);
    const [analysisType, setAnalysisType] = useState("analyze_cost");
    const [analysisText, setAnalysisText] = useState("");
    const [selectedDateRange, setSelectedDateRange] = useState("1 month");
    const [currentStartDate, setCurrentStartDate] = useState(dayjs().subtract(1, "month").toDate());
    const [currentEndDate, setCurrentEndDate] = useState(dayjs().toDate());
    const dateRangeOptions = ["1 month"];
    const tabs = [
        { label: "Analyse Costs", value: "analyze_cost" },
        // { label: "Analyse Savings", value: "analyze_savings" },
        { label: "Predict Future", value: "predict_future" },
    ];
    const pattern = /```[^`]*```|\n\n/gi;
    const replaceNewlines = (value) => value.replace(pattern, (match) => match.startsWith("```") ? match : " \n \n");
    const handleItemClick = (value) => {
        setSelectedDateRange(value);
        switch (value) {
            case "1 month":
                setCurrentStartDate(dayjs().subtract(1, "month").toDate());
                break;
            case "3 months":
                setCurrentStartDate(dayjs().subtract(3, "month").toDate());
                break;
            case "1 year":
                setCurrentStartDate(dayjs().subtract(1, "year").toDate());
                break;
            default:
                break;
        }
    };
    const generateAnalysis = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (isLoading)
            return;
        setIsLoading(true);
        setAnalysisText("");
        const url = `/summary/v2/datapilot/analysis`;
        const onProgress = (chunk) => {
            if (!analysisText && chunk.length > 0) {
                setIsLoading(false);
            }
            setAnalysisText((prev) => prev + chunk);
        };
        yield generateStream(url, onProgress, {
            start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
            end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
            summary_analysis_type: analysisType,
        })
            .then(() => {
            setIsLoading(false);
        })
            .catch((error) => {
            setAnalysisText(error.message);
            setIsLoading(false);
        });
    });
    useEffect(() => {
        generateAnalysis();
    }, [selectedDateRange, analysisType]);
    const onTabSelect = (value) => {
        setAnalysisType(value);
        if (value === "predict_future") {
            setCurrentStartDate(dayjs().startOf("year").toDate());
            setCurrentEndDate(dayjs().endOf("year").toDate());
        }
        else {
            setCurrentEndDate(dayjs().toDate());
        }
    };
    return (_jsxs(Card, { className: "p-3 pb-0", children: [_jsxs(CardTitle, { children: [_jsx("h5", { className: "text-black", children: "Datapilot Analysis" }), _jsx("p", { className: classes.caption, children: "Let DataPilot AI agents analyze and predict costs and savings for you" })] }), _jsxs(Stack, { children: [_jsx(Card, { className: "p-3", style: { width: 200 }, children: _jsx(Tabs, { className: classes.dp_tabs, items: tabs, selectedTab: analysisType, onTabSelect: onTabSelect, vertical: true }) }), _jsx(Card, { className: "p-3", style: { flex: 1 }, children: _jsxs("div", { children: [_jsxs(Stack, { className: "justify-content-between", children: [_jsx("h5", { children: (_a = tabs.find((t) => t.value === analysisType)) === null || _a === void 0 ? void 0 : _a.label }), _jsx(Dropdown, { value: selectedDateRange, onOptionSelect: handleItemClick, prefix: "Time period:", options: dateRangeOptions.map((value) => ({
                                                label: value,
                                                value,
                                            })) })] }), isLoading && _jsx(ComponentLoader, { label: "" }), _jsx("p", { className: classes.mkDownAnalysis, children: _jsx(ReactMarkdown, { remarkPlugins: [gfm, remarkBreaks], components: {
                                            code(_a) {
                                                var { node, inline, className, children } = _a, props = __rest(_a, ["node", "inline", "className", "children"]);
                                                const match = /language-(\w+)/.exec(className || "");
                                                return !inline && match ? (_jsx(SyntaxHighlighter, Object.assign({}, props, { style: vscDarkPlus, language: match[1], wrapLines: true, wrapLongLines: true, PreTag: "div", children: String(children).replace(/\n$/, "") }))) : (_jsx("code", Object.assign({}, props, { className: className, children: children })));
                                            },
                                        }, children: replaceNewlines(analysisText) }) })] }) })] }), _jsx("div", { id: "current" })] }));
};
export default DatapilotAnalysis;
