import styles from "./styles.module.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { uniqueId } from "lodash";
import { ReactComponent as DragIcon } from "../../assets/icons/drag.svg";
import { isEmpty } from "../../helpers/utils";
import { createMonacoEditor } from "./editorUtils";
import { CopyIconButtonFab } from "../CopyButton";

const decorationOptions = {
  isWholeLine: false,
  minimap: { color: "#D6768788", position: 1 },
  inlineClassName: styles.highlight,
};

const CodeSQLEditor = forwardRef(function CodeSQLEditor(
  {
    value = "",
    height = "60vh",
    minimap = false,
    lineNumbers = false,
    showCopy = false,
  },
  ref
) {
  const containerRef = useRef(null);
  const editorRef = useRef(null);
  const resizeRef = useRef(null);
  const editorId = useMemo(() => uniqueId("editor-"), []);
  useEffect(() => {
    createMonacoEditor(editorId, {
      language: "sql",
      readOnly: true,
      minimap,
      lineNumbers,
    }).then((editor) => {
      editor.setValue(value);
      editorRef.current = editor;
    });
  }, []);

  useEffect(() => {
    editorRef.current?.setValue(value);
  }, [value]);

  useEffect(() => {
    const resizableDiv = document.getElementById(editorId);
    const resizeHandle = resizeRef.current;
    let isResizing = false;
    resizeHandle.addEventListener("mousedown", (e) => {
      e.preventDefault();
      isResizing = true;
    });
    document.addEventListener("mousemove", (e) => {
      if (!isResizing) return;
      const newHeight = e.clientY - resizableDiv.getBoundingClientRect().top;
      resizableDiv.style.height = `${newHeight}px`;
    });
    document.addEventListener("mouseup", () => {
      isResizing = false;
    });
  }, []);

  useImperativeHandle(
    ref,
    () => {
      const removeHighlight = () => {
        const editor = editorRef.current;
        if (!editor) return;
        const lineCount = editor.getModel().getLineCount();
        editor.removeDecorations(
          editor
            .getDecorationsInRange({
              endColumn: 200,
              endLineNumber: lineCount,
              startColumn: 1,
              startLineNumber: 1,
            })
            .map((item) => item.id)
        );
      };

      const setHighlight = (spans) => {
        const editor = editorRef.current;
        if (!editor) return;
        editor.createDecorationsCollection(
          spans.map(({ index }) => ({
            range: {
              endColumn: index[1][1] + 1,
              endLineNumber: index[1][0] + 1,
              startColumn: index[0][1] + 1,
              startLineNumber: index[0][0] + 1,
            },
            options: decorationOptions,
          }))
        );
      };

      const highlightSpan = (range) => {
        const editor = editorRef.current;
        if (!editor) return;
        if (isEmpty(range.index)) return;
        editor.revealLinesInCenter(range.index[0][0], range.index[1][0]);
      };

      const scrollIntoView = () => {
        containerRef.current?.scrollIntoView();
      };

      const onParentResize = () => {
        // make editor as small as possible
        editorRef.current?.layout({ width: 0, height: 0 });

        // wait for next frame to ensure last layout finished
        window.requestAnimationFrame(() => {
          const parent = document.getElementById(editorId)?.parentElement;
          if (parent) {
            // get the parent dimensions and re-layout the editor
            const rect = parent.getBoundingClientRect();
            editorRef.current?.layout({
              width: rect.width,
              height: rect.height,
            });
          }
        });
      };

      return {
        setHighlight,
        removeHighlight,
        highlightSpan,
        scrollIntoView,
        onParentResize,
      };
    },
    []
  );

  return (
    <div className={styles.editor_container} ref={containerRef}>
      <div id={editorId} style={{ height }} />
      {showCopy && (
        <div className={styles.copy_button}>
          <CopyIconButtonFab value={value} />
        </div>
      )}
      <div className={styles.drag_icon} ref={resizeRef}>
        <DragIcon />
      </div>
    </div>
  );
});

export default CodeSQLEditor;
