import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Alert, Badge, Stack } from "@uicore";
import { Learnings, TeamMatesConfig, } from "@components";
import useComponentsHelper from "@/modules/app/useComponentsHelper";
import { Button } from "reactstrap";
import { ArrowLeftIcon } from "@/assets/icons";
import classes from "./teammate.module.scss";
const Teammate = () => {
    const { isComponentsApiInitialized } = useComponentsHelper();
    const { teammate } = useParams();
    const [searchParams] = useSearchParams();
    const learning = searchParams.get("learning");
    if (!isComponentsApiInitialized) {
        return null;
    }
    const teammateConfig = TeamMatesConfig.find((t) => t.key === teammate);
    if (!teammateConfig) {
        return _jsx(Alert, { color: "danger", children: "Unable to find teammate details." });
    }
    return (_jsxs("div", { className: classes.teammate, children: [_jsx("div", { children: _jsxs(Button, { className: classes.breadcrumb, color: "link", tag: Link, to: "/teammates", children: [_jsx(ArrowLeftIcon, {}), "Back to Teammates"] }) }), _jsxs(Stack, { className: "my-2", children: [_jsx("img", { src: teammateConfig.avatar, alt: `${teammate} image` }), _jsxs("div", { className: "flex-1", children: [_jsxs(Stack, { className: "align-items-center", children: [_jsx("h2", { className: "mb-0", children: teammateConfig.name }), " ", teammateConfig.availability.map((availability) => (_jsxs(Badge, { color: "success", tooltip: `Available in ${availability}`, className: availability, children: ["Available in ", availability] }, availability)))] }), _jsx("p", { children: teammateConfig.description })] })] }), _jsxs("div", { className: "mt-4", children: [_jsx("h5", { children: "Learnings" }), _jsx("p", { children: "These are learnings the AI teammate has gathered based on the coaching provided by users." }), _jsx(Learnings, { filters: { taskLabel: teammate }, learning: learning })] })] }));
};
export default Teammate;
