var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "reactstrap";
import { ReactComponent as QIB } from "@/assets/images/query_insights_background.svg";
import { useState } from "react";
import classNames from "classnames";
import styles from "../styles.module.scss";
import { generateStream } from "../../../helpers/api_helper";
import { TypeWriterLoader } from "../../../Components/Loader";
import { BotIcon, ExplanationDownIcon } from "@assets/icons";
import { CodeMarkdown } from "@/uiCore";
export const QueryExplanation = ({ queryId, queryHash, }) => {
    const [explanation, setExplanation] = useState("");
    const [explanationError, setExplanationError] = useState("");
    const [showExplanation, setShowExplanation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleQueryExplanation = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        let url = "";
        if (queryId) {
            url = "/copilot/quickchat/query-explain/v2/" + queryId;
        }
        else if (queryHash) {
            url = "/copilot/quickchat/query-group-explain/v2/" + queryHash;
        }
        else {
            console.error("No queryId or queryHash provided");
            setIsLoading(false);
            setExplanationError("No queryId or queryHash provided");
            return;
        }
        const onProgress = (chunk) => {
            if (!explanation && chunk.length > 0) {
                setIsLoading(false);
                setShowExplanation(true);
            }
            setExplanation((prev) => prev + chunk);
        };
        yield generateStream(url, onProgress).catch((error) => {
            setExplanationError(error.message);
            setIsLoading(false);
        });
    });
    const handleExplanationChange = () => {
        setShowExplanation((prev) => !prev);
    };
    return (_jsxs("div", { className: "d-flex flex-column", children: [_jsxs("div", { className: classNames(styles.query_explanation, {
                    [styles.clicked]: showExplanation || isLoading,
                }), children: [_jsx("div", { className: styles.title, children: "DataPilot can try to summarize the query for you" }), _jsx(QIB, { className: styles.query_insights_background }), explanation ? (_jsx(Button, { className: styles.arrow_button, outline: true, onClick: handleExplanationChange, children: showExplanation ? (_jsx(ExplanationDownIcon, {})) : (_jsx(ExplanationDownIcon, { className: styles.rotate_up })) })) : (_jsxs(Button, { className: styles.button, outline: true, onClick: handleQueryExplanation, children: [_jsx(BotIcon, {}), _jsx("div", { className: styles.text, children: isLoading ? "Generating..." : "Summarize Query" })] }))] }), isLoading && (_jsx(TypeWriterLoader, { label: "Query summarization may take a few seconds", loadingTexts: [
                    "Looking at some metadata",
                    "Checking query history",
                    "Analyzing data profile",
                    "Traversing data lineage",
                    "Adding some AI magic",
                ] })), showExplanation && (_jsx("div", { className: styles.explanation_popup, children: _jsx("div", { className: styles.explanation_text, children: _jsx(CodeMarkdown, { value: explanation ? explanation : explanationError }) }) }))] }));
};
