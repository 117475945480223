import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { ResourceTagColors } from "../../constants";
const ResourceTag = ({ resource, index = 0, onClick }) => {
    return (_jsx("div", { className: styles.resourceTag, style: {
            backgroundColor: ResourceTagColors[index % 3].color,
            color: ResourceTagColors[index % 3].textColor,
            width: "100%",
        }, onClick: (e) => {
            e.stopPropagation();
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }, children: _jsx("div", { className: styles.tag, children: _jsx("div", { className: styles.name, children: resource.resource_name }) }) }));
};
export default ResourceTag;
