import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdParty from "supertokens-auth-react/recipe/thirdparty";
import { REACT_APP_API_URL, REACT_APP_HOST } from "./config";
import Multitenancy from "supertokens-auth-react/recipe/multitenancy";
import { getTenant, isSSOEnabled } from "./helpers/tenant";
const ssoMode = isSSOEnabled();
export const initializeSuperTokens = () => {
    if (ssoMode) {
        SuperTokens.init({
            //   enableDebugLogs: true,
            appInfo: {
                appName: "altimate",
                apiDomain: REACT_APP_API_URL,
                websiteDomain: window.location.origin,
                apiBasePath: "/superauth",
                websiteBasePath: "/login",
            },
            style: `
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
      `,
            usesDynamicLoginMethods: true,
            recipeList: [
                Multitenancy.init({
                    override: {
                        functions: (oI) => {
                            return Object.assign(Object.assign({}, oI), { getTenantId: getTenant });
                        },
                    },
                }),
                ThirdParty.init(),
                Session.init({
                    sessionTokenFrontendDomain: `.${REACT_APP_HOST}`,
                    tokenTransferMethod: "header", // or "cookie"
                }),
            ],
        });
    }
};
export const SupertokensProvider = ({ children, }) => {
    return ssoMode ? (_jsx(SuperTokensWrapper, { children: children })) : (_jsx(_Fragment, { children: children }));
};
