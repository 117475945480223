var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Button as ReactStrapButton } from "reactstrap";
import Tooltip from "../tooltip/Tooltip";
export const Button = (_a) => {
    var { icon, children } = _a, restProps = __rest(_a, ["icon", "children"]);
    const [showButtonText, setShowButtonText] = useState(true);
    useEffect(() => {
        setShowButtonText(!icon);
    }, [icon]);
    const mouseHoverAction = (showBtnText) => {
        if (icon) {
            setShowButtonText(showBtnText);
        }
    };
    return (_jsx(Tooltip, { content: restProps.title, children: _jsxs(ReactStrapButton, Object.assign({}, restProps, { onMouseEnter: () => mouseHoverAction(true), onMouseLeave: () => mouseHoverAction(false), children: [icon && icon, " ", showButtonText && children] })) }));
};
