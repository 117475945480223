var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from 'rehype-raw';
const pattern = /```[^`]*```|\n\n/gi;
const replaceNewlines = (value) => (value || "").replace(pattern, (match) => match.startsWith("```") ? match : " \n \n");
function CodeMarkdown({ value }) {
    return (_jsx(ReactMarkdown, { remarkPlugins: [gfm, remarkBreaks], 
        // @ts-ignore
        rehypePlugins: [rehypeRaw], components: {
            code(_a) {
                var { node, inline, className, children } = _a, props = __rest(_a, ["node", "inline", "className", "children"]);
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (_jsx(SyntaxHighlighter, Object.assign({}, props, { style: vscDarkPlus, language: match[1], wrapLines: true, wrapLongLines: true, PreTag: "div", children: String(children).replace(/\n$/, "") }))) : (_jsx("code", Object.assign({}, props, { className: className, children: children })));
            },
        }, children: replaceNewlines(value) }));
}
export { CodeMarkdown };
