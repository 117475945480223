var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RelativeComponentLoader } from "@/Components/Loader";
import { getExportLineageItem } from "@/helpers/apis";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { ApiHelper, Lineage } from "@components";
export const ExportLineage = () => {
    const { itemId } = useParams();
    const { data, isLoading } = useQuery({
        queryKey: ["GET-EXPORT-LINEAGE", itemId],
        queryFn: () => getExportLineageItem(itemId),
        onSuccess: (data) => {
            // @ts-ignore
            ApiHelper.get = (url, _data) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                console.log("ApiHelper", url, _data);
                switch (url) {
                    case "getColumns": {
                        if (!((_a = data === null || data === void 0 ? void 0 : data.lineage_data) === null || _a === void 0 ? void 0 : _a.details))
                            return {};
                        const { columns, purpose } = data.lineage_data.details[_data.table];
                        return {
                            purpose: purpose,
                            columns: (columns === null || columns === void 0 ? void 0 : columns.map((c) => ({
                                name: c.name,
                                table: c.table,
                                datatype: c.datatype,
                                can_lineage_expand: c.can_lineage_expand,
                                description: c.description,
                            }))) || [],
                        };
                    }
                    default:
                        break;
                }
            });
        },
    });
    if (isLoading) {
        return _jsx(RelativeComponentLoader, { label: "Loading..." });
    }
    if (!data || !itemId) {
        return _jsx(Navigate, { to: "/collaboration/export-lineage" });
    }
    return (_jsxs("div", { children: [_jsx("div", { className: "fs-3 mb-2", children: data.name }), _jsx(Card, { children: _jsx(CardBody, { children: _jsx("div", { style: { height: "80vh" }, children: _jsx(Lineage, { theme: "light", staticLineage: data.lineage_data, lineageType: "static" }) }) }) })] }));
};
