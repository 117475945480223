import { getTenantInfo } from "@/helpers/apis";
import { setCurrency, setTeammatesEnabled } from "./appSlice";
import { USD } from "./constants";
import { useState } from "react";
const useAppActions = (dispatch) => {
    const [loadingTenantInfo, setLoadingTenantInfo] = useState(false);
    const fetchTenantInfo = () => {
        return new Promise((resolve, reject) => {
            setLoadingTenantInfo(true);
            getTenantInfo()
                .then((response) => {
                // @ts-ignore
                window.posthog.capture('tenant_info', {
                    response
                });
                dispatch(setCurrency((response === null || response === void 0 ? void 0 : response.currency) || USD));
                dispatch(setTeammatesEnabled((response === null || response === void 0 ? void 0 : response.teammatesEnabled) || false));
                resolve();
            }).catch((err) => {
                reject(err);
            })
                .finally(() => {
                setLoadingTenantInfo(false);
            });
        });
    };
    return {
        fetchTenantInfo,
        loadingTenantInfo,
    };
};
export default useAppActions;
