import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterTagWrapper } from "@/Components/Tags";
import { addSearchParams, removeSearchParams } from "@/helpers/utils";
import { useAppState } from "@/modules/app/useAppContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueryList } from "../Query/QueryList";
import { Paginate } from "@/Components/Paginate";
import { getAllQueries, getQueryFilters } from "@/helpers/backend_helper";
import { RelativeComponentLoader } from "@/Components/Loader";
import { useState } from "react";
import { getDateByEndOfDayWithoutTimeoffset, getDateByStartOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import { useQuery } from "react-query";
import { GET_QUERY, GET_QUERY_FILTERS } from "../Query/constants";
import InsightLabel from "../Query/InsightLabel";
export const WarehouseQueries = ({ startDate, endDate, warehouse }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { currencySymbol } = useAppState();
    const tagFilter = searchParams.getAll("tags") || [];
    const executionTimeFilter = searchParams.get("executionTime");
    const queryCostFilter = searchParams.get("queryCost");
    const userFilter = searchParams.getAll("users") || [];
    const [sortAttribute, setSortAttribute] = useState("query_cost");
    const [sortOrder, setSortOrder] = useState({
        query_cost: "",
        end_time: "",
        execution_time: "",
    });
    const executionTimeFilterMode = searchParams.get("executionTimeFilterMode") || "gt";
    const executionCostFilterMode = searchParams.get("executionCostFilterMode") || "gt";
    const handleCostSortChange = (so) => {
        if (so) {
            setSortAttribute("query_cost");
            setSortOrder((prevSortOrder) => (Object.assign(Object.assign({}, prevSortOrder), { query_cost: so })));
        }
    };
    const handleTimestampSortChange = (so) => {
        if (so) {
            setSortAttribute("end_time");
            setSortOrder((prevSortOrder) => (Object.assign(Object.assign({}, prevSortOrder), { end_time: so })));
        }
    };
    const handleExecutionTimeSortChange = (so) => {
        if (so) {
            setSortAttribute("execution_time");
            setSortOrder((prevSortOrder) => (Object.assign(Object.assign({}, prevSortOrder), { execution_time: so })));
        }
    };
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const getFilterParams = () => {
        var _a;
        const params = {
            page: page + 1,
            size: 8,
            warehouses: [warehouse],
        };
        if (tagFilter.length > 0)
            params.tags = tagFilter;
        if (userFilter.length > 0)
            params.users = userFilter;
        if (sortAttribute) {
            params.sortAttribute = sortAttribute;
            params.sortOrder = sortOrder[sortAttribute];
        }
        if (executionTimeFilter) {
            params.executionTimeFilter =
                parseFloat(executionTimeFilter) * (60 * 1000);
            params.executionTimeFilterMode =
                executionTimeFilterMode === "lt" ? "lt" : "gt";
        }
        if (queryCostFilter) {
            params.executionCostFilter = queryCostFilter;
            params.executionCostFilterMode =
                executionCostFilterMode === "lt" ? "lt" : "gt";
        }
        params.start_date =
            getDateByStartOfDayWithoutTimeoffset(startDate).toISOString();
        params.end_date =
            (_a = getDateByEndOfDayWithoutTimeoffset(endDate)) === null || _a === void 0 ? void 0 : _a.toISOString();
        return params;
    };
    const { data: filters, isLoading: isfiltersLoading } = useQuery({
        queryKey: [GET_QUERY_FILTERS],
        queryFn: () => getQueryFilters(),
    });
    const { data: queries, isLoading: isQueriesLoading } = useQuery({
        queryKey: [
            GET_QUERY,
            page,
            ...tagFilter,
            ...userFilter,
            sortOrder[sortAttribute],
            sortAttribute,
            executionTimeFilter,
            queryCostFilter,
            startDate,
            endDate,
            executionTimeFilterMode,
            executionCostFilterMode,
        ],
        queryFn: () => getAllQueries(getFilterParams()),
    });
    const handleCostFilterChange = ({ value, mode, }) => {
        // Fix the below type
        // @ts-ignore
        setSearchParams((prev) => {
            prev = addSearchParams("queryCost", value)(prev);
            prev = addSearchParams("executionCostFilterMode", mode)(prev);
            return prev;
        });
    };
    const handleExecutionTimeFilterChange = ({ value, mode, }) => {
        // Fix the below type
        // @ts-ignore
        setSearchParams((prev) => {
            prev = addSearchParams("executionTime", value)(prev);
            prev = addSearchParams("executionTimeFilterMode", mode)(prev);
            return prev;
        });
    };
    const handleUserFilterChange = (value) => {
        // @ts-ignore
        setSearchParams(addSearchParams("users", value));
    };
    const handleInsightsFilterChange = (value) => {
        // @ts-ignore
        setSearchParams(addSearchParams("tags", value));
    };
    if (isQueriesLoading || isfiltersLoading) {
        return _jsx(RelativeComponentLoader, { label: "Loading queries..." });
    }
    return (_jsxs("div", { children: [_jsx("div", { className: "d-flex flex-wrap gap-sm mb-2", children: _jsx(FilterTagWrapper, { filters: {
                        tagFilter: {
                            filterStr: tagFilter,
                            label: "Tags",
                            onclose: (removedFilter) => {
                                // @ts-ignore
                                setSearchParams(removeSearchParams("tags", removedFilter));
                            },
                        },
                        userFilter: {
                            filterStr: userFilter,
                            label: "Users",
                            onclose: (removedFilter) => {
                                // @ts-ignore
                                setSearchParams(removeSearchParams("users", removedFilter));
                            },
                        },
                        executionTimeFilter: {
                            filterStr: executionTimeFilter &&
                                `${executionTimeFilterMode === "lt" ? "<" : ">"} ${executionTimeFilter}`,
                            label: "mins",
                            onclose: () => {
                                // Fix the below type
                                // @ts-ignore
                                setSearchParams((prev) => {
                                    prev = removeSearchParams("executionTime")(prev);
                                    prev = removeSearchParams("executionTimeFilterMode")(prev);
                                    return prev;
                                });
                            },
                        },
                        queryCostFilter: {
                            filterStr: queryCostFilter &&
                                `${executionCostFilterMode === "lt" ? "<" : ">"} ${queryCostFilter}`,
                            label: currencySymbol,
                            onclose: () => {
                                // Fix the below type
                                // @ts-ignore
                                setSearchParams((prev) => {
                                    prev = removeSearchParams("queryCost")(prev);
                                    prev = removeSearchParams("executionCostFilterMode")(prev);
                                    return prev;
                                });
                            },
                        },
                    } }) }), _jsx(QueryList, { header: [
                    { id: 1, label: "Query Text" },
                    { id: 111, label: "Query Hash" },
                    {
                        id: 2,
                        label: "Est. Cost",
                        filter: {
                            filterType: "text",
                            value: queryCostFilter,
                            placeHolder: `Specify cost in ${currencySymbol}`,
                            onChange: handleCostFilterChange,
                            label: "Estimate Cost",
                            searchParamKey: "queryCost",
                            filterMode: { value: executionCostFilterMode },
                        },
                        sort: {
                            onChange: handleCostSortChange,
                            value: sortOrder.query_cost,
                        },
                    },
                    {
                        id: 3,
                        label: "Exec. Time",
                        filter: {
                            filterType: "text",
                            value: executionTimeFilter,
                            placeHolder: "Specify time in mins",
                            onChange: handleExecutionTimeFilterChange,
                            label: "Execution Time",
                            searchParamKey: "executionTime",
                            filterMode: { value: executionTimeFilterMode },
                            allowFractional: true,
                        },
                        sort: {
                            onChange: handleExecutionTimeSortChange,
                            value: sortOrder.execution_time,
                        },
                    },
                    {
                        id: 4,
                        label: "Insights",
                        filter: {
                            filterType: "dropdown",
                            searchParamKey: "tags",
                            value: tagFilter,
                            searchBy: "value",
                            options: (filters === null || filters === void 0 ? void 0 : filters.tags)
                                ? filters.tags.map((t) => ({
                                    label: _jsx(InsightLabel, { tag: t }),
                                    value: t.name,
                                }))
                                : [],
                            onChange: handleInsightsFilterChange,
                        },
                    },
                    {
                        id: 5,
                        label: "Timestamp",
                        sort: {
                            onChange: handleTimestampSortChange,
                            value: sortOrder.end_time,
                        },
                    },
                    { id: 6, label: "Query Type" },
                    {
                        id: 8,
                        label: "User",
                        filter: {
                            filterType: "dropdown",
                            searchParamKey: "users",
                            value: userFilter,
                            options: (filters === null || filters === void 0 ? void 0 : filters.users)
                                ? filters.users.map((w) => ({
                                    label: w,
                                    value: w,
                                }))
                                : [],
                            onChange: handleUserFilterChange,
                        },
                    },
                ], onRowClick: (v) => navigate(`/query/${v}${startDate ? "?start_date=" + startDate : ""}${endDate ? "&end_date=" + endDate : ""}`), queries: queries, resetPage: setPage, showWarehouse: false }), _jsx(Paginate, { itemCount: queries.total, page: page, pageSize: queries.size, numPages: queries.pages, onPageClick: setPage, onPageSizeChange: () => { } })] }));
};
