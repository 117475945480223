import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
export const initialState = {
    loading: false,
    currentEndDate: dayjs().toDate(),
    currentStartDate: dayjs().subtract(7, "day").toDate(),
    showChatbot: undefined,
};
const summarySlice = createSlice({
    name: "summaryState",
    initialState,
    reducers: {
        setShowChatbot: (state, action) => {
            state.showChatbot = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCurrentStartDate: (state, action) => {
            state.currentStartDate = action.payload;
        },
        setCurrentEndDate: (state, action) => {
            state.currentEndDate = action.payload;
        },
    },
});
export const { setLoading, setCurrentEndDate, setCurrentStartDate, setShowChatbot, } = summarySlice.actions;
export default summarySlice;
