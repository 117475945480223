import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
export const DynamicHTML = ({ htmlContent }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        var _a;
        if (containerRef.current) {
            const links = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getElementsByTagName("a");
            Array.from(links).forEach((link) => {
                link.addEventListener("click", (event) => {
                    event.stopPropagation();
                    if (!link.target) {
                        link.target = "_blank";
                    }
                    if (!link.rel) {
                        link.rel = "noopener noreferrer";
                    }
                });
            });
        }
    }, [htmlContent]);
    return (_jsx("div", { ref: containerRef, dangerouslySetInnerHTML: { __html: htmlContent } }));
};
