import dayjs from "dayjs";
import _ from "lodash";
import { formatNumber as fN } from "@/uiCore";
export const getTrueKeys = (x) => {
    const result = [];
    x.forEach((v, k) => {
        if (!v)
            return;
        if (Array.isArray(k))
            result.push(...k);
        else
            result.push(k);
    });
    return result;
};
export const isEmpty = (arr) => !arr || !Array.isArray(arr) || arr.length === 0;
// add proper types for these functions
export const toggleSetValue = (s, key) => s.delete(key) ? s : s.add(key);
export const getScopeString = (scope) => scope.join("/");
export const isStrDateOlderThan = (dateStr, numDays) => dayjs().diff(dayjs(dateStr, "DD-MMM-YY HH:mm"), "day") > numDays;
export const containsScope = (scopeArr, scope) => scopeArr.length === 0
    ? false
    : !!scopeArr.find((item) => _.isEqual(item, scope));
export const downloadBlob = (blob) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a); // append the element to the dom
    a.click();
    a.remove(); // afterwards, remove the element
};
export function downloadFileFromUrl(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export const onSortChange = (key, setSort, setSortAttribute) => (newOrder) => {
    setSort((prevSortOrder) => (Object.assign(Object.assign({}, prevSortOrder), { [key]: newOrder })));
    setSortAttribute(key);
};
export const roundToTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
};
export const addSearchParams = (key, value) => (prev) => {
    const params = new URLSearchParams(prev);
    if (value) {
        if (value instanceof Array) {
            params.delete(key);
            value.forEach((v) => params.append(key, v));
        }
        else {
            params.set(key, value);
        }
    }
    else {
        params.delete(key);
    }
    return params.toString();
};
export const removeSearchParams = (key, value) => (prev) => {
    const params = new URLSearchParams(prev);
    if (value) {
        // @ts-ignore valid api
        params.delete(key, value);
    }
    else {
        params.delete(key);
    }
    return params.toString();
};
export const removeMultipleSearchParams = (keys) => (prev) => {
    const params = new URLSearchParams(prev);
    keys.forEach((key) => params.delete(key));
    return params.toString();
};
export const getUserById = (users, id) => users === null || users === void 0 ? void 0 : users.find((u) => u.id === id);
export function formatNumber(n, wholeNumber = false) {
    const number = parseFloat(n);
    const divisors = {
        "1000": "",
        "1000000": "K",
        "1000000000": "M",
        "1000000000000": "B",
    };
    let divisor = 1000000000000;
    let suffix = "B";
    for (let l in divisors) {
        const limit = Number(l);
        if (number < Number(limit)) {
            divisor = limit / 1000;
            suffix = divisors[l];
            break;
        }
    }
    let formattedNumber;
    if (1000000 <= number && number < 1000000000) {
        formattedNumber = (number / 1000000).toFixed(1) + "M";
    }
    else {
        formattedNumber =
            number >= 10 || wholeNumber
                ? (Math.ceil((number / divisor) * 10) / 10 + suffix).replace(".0", "")
                : number.toFixed(2);
    }
    return formattedNumber;
}
export const formatDate = (date) => {
    if (typeof date === "string") {
        try {
            const formattedDate = dayjs(date).format("DD MMM, YYYY");
            if (date.includes("<")) {
                return "Before " + formattedDate;
            }
            else if (date.includes("N/A")) {
                return "N/A";
            }
            return formattedDate;
        }
        catch (_a) {
            return date;
        }
    }
    else {
        return "None";
    }
};
export const formatCost = (cost, currency) => {
    try {
        if (isNaN(parseFloat(cost))) {
            return cost;
        }
        return fN(cost, { currency });
    }
    catch (_a) {
        return cost;
    }
};
