var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api } from "@/helpers/api_helper";
import { ApiHelper } from "@components";
import { useState, useEffect } from "react";
const useComponentsHelper = () => {
    const [isComponentsApiInitialized, setIsComponentsApiInitialized] = useState(false);
    useEffect(() => {
        console.info("updating components api helper");
        // This overrides the components library api methods
        // @ts-ignore
        ApiHelper.get = (url, data, _request) => __awaiter(void 0, void 0, void 0, function* () {
            return api.get(url, data);
        });
        // @ts-ignore
        ApiHelper.post = (url_1, data_1, ...args_1) => __awaiter(void 0, [url_1, data_1, ...args_1], void 0, function* (url, data, request = {}) {
            // TODO updat api helper to handle other request methods
            if (request.method === "DELETE") {
                return api.delete(url, data);
            }
            if (request.method === "PUT") {
                return api.update(url, data);
            }
            return api.post(url, data);
        });
        setIsComponentsApiInitialized(true);
    }, []);
    return {
        isComponentsApiInitialized,
    };
};
export default useComponentsHelper;
