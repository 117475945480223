import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Badge, Card, CardBody, CardTitle, Chart, ChartTypes, formatNumber, IconButton, NumberFormatter, Stack, } from "@uicore";
import { Cell, Pie } from "recharts";
import classes from "../summary.module.scss";
import classNames from "classnames";
import { useAppState } from "@/modules/app/useAppContext";
import { InfoIcon } from "@/assets/icons";
const COLORS = ["#0088FE", "#00C49F"];
// TODO: refactor this
const CustomTooltip = ({ active, payload, data, }) => {
    const { currency } = useAppState();
    if (active && payload && payload.length) {
        return (_jsxs("div", { className: classNames("custom-tooltip bg-white px-2 py-1 border-4 rounded"), children: [_jsxs(Badge, { tooltip: "", color: "orange", className: "mb-2", children: ["Total Savings:", _jsx(NumberFormatter, { options: { currency }, value: data.total_savings })] }), _jsxs("p", { children: [_jsx("span", { className: "px-2 rounded me-1", style: { background: COLORS[0] } }), "Autonomous:", formatNumber(data.total_autonomous_savings, {
                            currency,
                        })] }), _jsxs("p", { children: [_jsx("span", { className: "px-2 rounded me-1", style: { background: COLORS[1] } }), "Assisted:", formatNumber(data.total_assisted_savings, {
                            currency,
                        })] })] }));
    }
    return null;
};
const SavingsInFuture = ({ data }) => {
    const graphData = data
        ? [
            {
                name: "total_autonomous_savings",
                label: "Autonomous",
                value: data.total_autonomous_savings,
            },
            {
                name: "total_assisted_savings",
                label: "Assisted",
                value: data.total_assisted_savings,
            },
        ]
        : [];
    if (!data) {
        return _jsx(Alert, { children: "No data available" });
    }
    return (_jsxs(Stack, { children: [_jsxs(Card, { style: { height: 400, padding: "1rem", flex: 1 }, children: [_jsxs(CardTitle, { className: "d-flex justify-content-between align-items-center", children: ["Money Savings Per Month", _jsx(IconButton, { className: "p-0", title: "Projected money savings per month", children: _jsx(InfoIcon, {}) })] }), _jsx(CardBody, { children: _jsx(Chart, { type: ChartTypes.PieChart, data: graphData, xAxisDataKey: "name", title: "Money Savings", hideFullscreenButton: true, tooltipProps: {
                                content: (props) => (_jsx(CustomTooltip, Object.assign({}, props, { data: data }))),
                            }, children: ({}) => (_jsx(Pie, { dataKey: "value", data: graphData, fill: "#247EFE", children: graphData.map((_entry, index) => (_jsx(Cell, { fill: COLORS[index % COLORS.length], name: _entry.label }, `cell-${index}`))) })) }) })] }), _jsxs(Card, { className: classes.donutChart, style: { padding: "1rem", flex: 1 }, children: [_jsxs(CardTitle, { className: "d-flex justify-content-between align-items-center", children: ["Time Savings Per Month", _jsx(IconButton, { className: "p-0", title: "Projected time savings per month", children: _jsx(InfoIcon, {}) })] }), _jsx(CardBody, { children: _jsx("div", { children: data.time_saved }) })] })] }));
};
export default SavingsInFuture;
