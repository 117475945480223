export var Effort;
(function (Effort) {
    Effort["LOW"] = "low";
    Effort["MEDIUM"] = "medium";
    Effort["HIGH"] = "high";
    Effort["ZERO"] = "zero";
})(Effort || (Effort = {}));
export var Status;
(function (Status) {
    Status["ACTIVE"] = "active";
    Status["IMPLEMENTED"] = "implemented";
    Status["DISMISSED"] = "dismissed";
})(Status || (Status = {}));
export var Period;
(function (Period) {
    Period["DAY"] = "day";
    Period["WEEK"] = "week";
    Period["MONTH"] = "month";
})(Period || (Period = {}));
export var ResourceType;
(function (ResourceType) {
    ResourceType[ResourceType["QueryHash"] = 1] = "QueryHash";
    ResourceType[ResourceType["Table"] = 2] = "Table";
    ResourceType[ResourceType["Warehouse"] = 3] = "Warehouse";
    ResourceType[ResourceType["User"] = 4] = "User";
    ResourceType[ResourceType["Role"] = 5] = "Role";
})(ResourceType || (ResourceType = {}));
