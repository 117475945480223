var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateTenantInfo } from "@/helpers/apis";
import useAppActions from "@/modules/app/useAppActions";
import useAppContext from "@/modules/app/useAppContext";
import { FormGroup, Tooltip, Input, Label } from "@uicore";
import { useState } from "react";
const LearningsToggle = () => {
    const [loading, setLoading] = useState(false);
    const { state: { teammatesEnabled }, dispatch, } = useAppContext();
    const { fetchTenantInfo } = useAppActions(dispatch);
    const handleChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield updateTenantInfo({ teammatesEnabled: e.target.checked });
            yield fetchTenantInfo();
        }
        catch (err) {
            console.error(err);
        }
        setLoading(false);
    });
    return (_jsx(FormGroup, { switch: true, children: _jsxs(Tooltip, { content: !teammatesEnabled ? "Opt in" : "Opt out", children: [_jsx(Input, { type: "switch", role: "switch", id: `toggle-enable-teammates`, checked: teammatesEnabled, disabled: loading, onChange: (e) => {
                        handleChange(e);
                    } }), _jsx(Label, { for: `toggle-enable-teammates`, children: loading ? "Saving..." : null })] }) }));
};
export default LearningsToggle;
