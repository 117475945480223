import React, { useCallback, useRef, useState } from "react";
import { Label, Form, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FormInput, FormPassword, FormSubmit } from "./components/form";
import { BaseContainer, Header } from "./components/base";
import {
  isFreeEmailProvider,
  validateEmail,
  validateFormPassword,
} from "./helper";
import { useMutation } from "react-query";
import {
  companyAvailable,
  companyRegister,
} from "../../helpers/backend_helper";
import styles from "./components/styles.module.scss";
import { ReactComponent as TickSquareIcon } from "../../assets/icons/tick_square.svg";
import { ReactComponent as InfoSquareIcon } from "../../assets/icons/info_square.svg";
import { ReactComponent as DangerCircleCheck } from "../../assets/icons/circle_check_danger.svg";
import { ReactComponent as SuccessCircleCheck } from "../../assets/icons/circle_check_enabled.svg";
import { ReactComponent as DisabledCircleCheck } from "../../assets/icons/circle_check_disabled.svg";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow_down.svg";
import classNames from "classnames";
import { debounce } from "lodash";
import { FREE_USER_DOMAIN } from "../../helpers/tenant";
import query_insights_robot from "../../assets/images/query_insights_robot.png";

import MailChecker from "mailchecker";
import { freeEmailDomains } from "./freeEmailDomains";
import { PasswordValidation } from "./PasswordValidator";
import { Dropdown } from "../../Components/Form";
import { SignupOptionsComponents } from "./components/misc";

const HOW_DID_YOU_HEAR_ABOUT_US = "free-user-tenant-source-enquiry";
const TENANT_INPUT_ID = "free-user-tenant-input";
const EMAIL_INPUT_ID = "free-user-email-input";
const PASSWORD_INPUT_ID = "free-user-password-input";
const OTHER_INPUT_ID = "other-input";

const passwordValidations = [
  "Use at least 8 characters",
  "Use a mix of upper and lower case characters",
  "Use 1 or more numbers",
  "Use 1 or more special characters",
];

const FreeRegister = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isExtensionVisitor = searchParams.get("source") === "extension";

  const [validatePassword, setValidatePassword] = useState([0, 0, 0, 0]);
  const [activeElement, setActiveElement] = useState(null);
  const [validTenant, setIsValidTenant] = useState(true);
  const defaultTenantMessage =
    "Only use lowercase letters & numbers, without any special characters";
  const [tenantError, setTenantError] = useState(defaultTenantMessage);
  const [emailError, setEmailError] = useState("");
  const {
    mutate: registerCompany,
    error,
    isLoading,
  } = useMutation(companyRegister, {
    onSuccess: (response) => {
      if (response.ok) {
        navigate("/verified", { state: { email: validation.values.email } });
      }
    },
    onError: (response) => {
      if (response === "sendgrid_invalid_email") {
        setEmailError(
          "Email validation failed. Please double-check your address or contact us at signup@altimate.ai for assistance."
        );
      }
    },
  });

  const onFocusChange = (e) => {
    setActiveElement(e.target);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: "",
      email: "",
      name: "",
      password: "",
      newsletter: true,
      source_insight: "",
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Please Enter Your Instance Name"),
      email: Yup.string().required("Please Enter Your Email"),
      name: Yup.string().required("Please Enter Your Name"),
    }),
    onSubmit: async (values) => {
      // Revalidate the form before submitting
      await isTenantAvailable(validation.values.company);
      await validation.validateForm();
      const email = validation.values.email;
      let isValidEmail = false;
      if (!email) {
        isValidEmail = true;
      } else if (isFreeEmailProvider(email)) {
        setEmailError("Please use a valid business email address");
      } else if (!MailChecker.isValid(email)) {
        setEmailError("Please use a valid email address");
      } else {
        isValidEmail = true;
        setEmailError("");
      }
      const isValidtenant =
        isTenantValid(validation.values.company) && validTenant;
      let validPassword = [1, 1, 1, 1];
      const password = validation.values.password;
      if (password.length < 8) validPassword[0] = 0; // at least 8 characters
      if (!/[a-z]/.test(password)) validPassword[1] = 0; // at least 1 lower case characters
      if (!/[A-Z]/.test(password)) validPassword[1] = 0; // at least 1 upper case characters
      if (!/[0-9]/.test(password)) validPassword[2] = 0; // 1 or more numbers
      if (!/[!-/:-@[-`{-~]/.test(password)) validPassword[3] = 0; // 1 or more special characters
      const isValidPassword = !validPassword.includes(0);

      validation.values.source_insight = signupSource;

      if (
        validation.isValid &&
        isValidtenant &&
        isValidEmail &&
        isValidPassword
      ) {
        console.log("Form is valid. Submitting");
        registerCompany(values);
      } else {
        console.log("Form has errors. Cannot submit.");
      }
    },
  });

  const isTenantValid = (tenantName) => {
    const isValid =
      ![
        "app",
        "www",
        "tenant",
        "api",
        "public",
        "postgres",
        "private",
      ].includes(tenantName) && /^[a-z_][a-z0-9_]*$/.test(tenantName);
    setIsValidTenant(isValid);
    return isValid;
  };

  const { mutate: isTenantAvailable } = useMutation(companyAvailable, {
    onSuccess: (data) => {
      // setTenantIcon(data.ok ? "available" : "unavailable");
      if (!data.ok) {
        setTenantError(
          "This instance name has been used already. Please check with your team if<br />they can invite you to the instance. You can also choose another instance name."
        );
        setIsValidTenant(false);
      } else {
        setTenantError(defaultTenantMessage);
        setIsValidTenant(true);
      }
    },
    onError: (data) => {
      setTenantError(data);
      setIsValidTenant(false);
    },
  });
  const debouncedIsTenantAvailable = useCallback(
    debounce(
      (name) => {
        if (name !== "") {
          isTenantValid(name) && isTenantAvailable(name);
        }
      },
      500,
      { trailing: true }
    ),
    []
  );

  const isFormDisabled = () => {
    if (validation.values.company === "") return true;
    if (validation.values.email === "") return true;
    if (validation.values.name === "") return true;

    if (emailError !== "") return true;
    if (!validTenant) return true;
    if (validatePassword.includes(-1) || validatePassword.includes(0))
      return true;

    return false;
  };

  const isMobile = window.innerWidth <= 768;
  const [signupSource, setSignupSource] = useState("");
  const [otherSelected, setOtherSelected] = useState(false);
  const signupSourceOther = useRef(undefined);

  const onOtherSelect = (e) => {
    setOtherSelected(true);
    setSignupSource("");
  };

  const onChangeSelectSource = (src) => {
    setSignupSource(src || signupSource || "Other");
    if (src) setOtherSelected(false);
  };

  const signupSources = [
    {
      label: (
        <SignupOptionsComponents
          option="Recommendation from a colleague or friend"
          signupSource={signupSource}
        />
      ),
      value: "Recommendation from a colleague or friend",
    },
    {
      label: (
        <SignupOptionsComponents
          option="VSCode Marketplace"
          signupSource={signupSource}
        />
      ),
      value: "VSCode Marketplace",
    },
    {
      label: (
        <SignupOptionsComponents
          option="Social Media (e.g., Twitter, LinkedIn, Facebook)"
          signupSource={signupSource}
        />
      ),
      value: "Social Media (e.g., Twitter, LinkedIn, Facebook)",
    },
    {
      label: (
        <SignupOptionsComponents
          option="Online Advertisement"
          signupSource={signupSource}
        />
      ),
      value: "Online Advertisement",
    },
    {
      label: (
        <SignupOptionsComponents
          option="Blog Post"
          signupSource={signupSource}
        />
      ),
      value: "Blog Post",
    },
    {
      label: (
        <SignupOptionsComponents
          option="Educational Course or Workshop"
          signupSource={signupSource}
        />
      ),
      value: "Educational Course or Workshop",
    },
    {
      label: (
        <div
          className={styles.select_source_options}
          key="Other"
          onClick={(e) => {
            if (!otherSelected) {
              e.stopPropagation();
              document.getElementById(OTHER_INPUT_ID).focus();
            }
          }}
        >
          <input
            className="me-2"
            type="radio"
            checked={otherSelected}
            readOnly
          />
          <div className="d-flex gap-md align-items-center">
            <div className="text-muted">Other:</div>
            <FormInput
              id={OTHER_INPUT_ID}
              className={styles.other_input}
              type="text"
              placeholder="(Please specify)"
              value={signupSourceOther.current}
              onFocus={onOtherSelect}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.stopPropagation();
                signupSourceOther.current = e.target.value;
              }}
              onBlur={() => {
                if (signupSourceOther.current)
                  setSignupSource(signupSourceOther.current);
                signupSourceOther.current = undefined;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setSignupSource(signupSourceOther.current);
                  onChangeSelectSource(signupSourceOther.current);
                  setOtherSelected(true);
                  signupSourceOther.current = undefined;
                }
              }}
            />
          </div>
        </div>
      ),
      value: signupSourceOther.current,
    },
  ];

  return (
    <BaseContainer
      footer={
        !isLoading ? (
          <div>
            <span className="text-muted fw-400">Already have an account? </span>
            <Link
              to="https://app.myaltimate.com/login"
              className="text-primary"
            >
              Sign In
            </Link>
            .
          </div>
        ) : null
      }
    >
      {isLoading && (
        <div
          className={classNames("d-flex flex-column align-items-center", {
            [styles.loader]: isMobile,
          })}
        >
          <img
            src={query_insights_robot}
            className={classNames({
              [styles.loading_image]: !isMobile,
              [styles.mobile_loading_image]: isMobile,
            })}
          />
          <div className="text-black fs-4 mt-4">
            Instance is being created, It will take about a minute
          </div>
        </div>
      )}
      {!isLoading && (
        <div className={classNames({ [styles.mobile_register]: isMobile })}>
          {isExtensionVisitor && (
            <div className={styles.vscode_visitor}>
              If you want to know more details about Altimate AI, please visit
              the{" "}
              <a
                href="https://www.altimate.ai"
                target="_blank"
                rel="noreferrer"
              >
                website
              </a>{" "}
              and{" "}
              <a
                href="https://docs.myaltimate.com"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>
              .
            </div>
          )}
          <Header>Sign Up</Header>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
            className={`${isMobile ? "w-100 mt-3" : "mt-4"}`}
          >
            <div className="mb-3">
              <Label htmlFor="name" className="form-label">
                Name
              </Label>
              <FormInput
                name="name"
                type="text"
                placeholder="Name"
                onChange={validation.handleChange}
                onFocus={onFocusChange}
                onBlur={validation.handleBlur}
                disabled={validation.isSubmitting}
              />
            </div>

            <div className="mb-3">
              <Label htmlFor="useremail" className="form-label">
                Business Email
              </Label>
              <div className="d-flex flex-column">
                <div
                  className={classNames(styles.tenant_form_input, {
                    [styles.in_focus]: activeElement?.id === EMAIL_INPUT_ID,
                    [styles.is_invalid]: emailError !== "",
                  })}
                >
                  <FormInput
                    id={EMAIL_INPUT_ID}
                    name="email"
                    type="email"
                    placeholder="name@company.com"
                    value={validation.values.email.toLowerCase()}
                    onChange={validation.handleChange}
                    onFocus={onFocusChange}
                    onBlur={(e) => {
                      setEmailError(
                        validateEmail(e.target.value.toLowerCase())
                      );
                      if (emailError === "") {
                        const emailParts = e.target.value.split("@");
                        if (emailParts.length === 2) {
                          const domainParts = emailParts[1].split(".");
                          if (
                            !validation.values.company &&
                            domainParts.length >= 2
                          ) {
                            validation.setFieldValue(
                              "company",
                              domainParts[0].toLowerCase()
                            );
                            debouncedIsTenantAvailable(
                              domainParts[0].toLowerCase()
                            );
                          }
                        }
                      }
                    }}
                    disabled={validation.isSubmitting}
                    style={{ marginLeft: "-0.75rem", paddingLeft: "0.75rem" }}
                  />
                  {validation.values.email !== "" && (
                    <div className={styles.icon}>
                      {emailError === "" ? (
                        <TickSquareIcon />
                      ) : (
                        <InfoSquareIcon />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {emailError !== "" && (
                <div
                  className={classNames(styles.form_feedback, {
                    [styles.is_error]: emailError !== "",
                  })}
                >
                  <span>{emailError}</span>
                </div>
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="password" className="form-label">
                Password
              </Label>
              <div
                className={classNames(styles.tenant_form_password, {
                  [styles.in_focus]:
                    activeElement?.id === PASSWORD_INPUT_ID ||
                    validatePassword.includes(-1),
                  [styles.is_invalid]: validatePassword.includes(-1),
                })}
              >
                <FormPassword
                  id={PASSWORD_INPUT_ID}
                  name="password"
                  placeholder="Password"
                  onFocus={onFocusChange}
                  onBlur={() => {
                    setValidatePassword((prev) =>
                      prev.map((element) => (element === 0 ? -1 : element))
                    );
                  }}
                  onChange={(e) => {
                    // Update Formik's state
                    validation.handleChange(e);
                    // Then validate the password
                    validateFormPassword(e.target.value, setValidatePassword);
                  }}
                  disabled={validation.isSubmitting}
                  style={{ marginLeft: "-0.75rem", paddingLeft: "0.75rem" }}
                />
              </div>
              {(activeElement?.id === PASSWORD_INPUT_ID ||
                validatePassword.includes(-1)) && (
                <PasswordValidation validatePassword={validatePassword} />
              )}
            </div>

            <div className="mb-3">
              <Label htmlFor="company" className="form-label">
                Instance Name
              </Label>
              <div className="text-muted mb-2">
                This is the name of the Altimate AI instance for your company.
              </div>
              <div className="d-flex flex-column">
                <label
                  className={classNames(styles.tenant_form_input, {
                    [styles.in_focus_company]:
                      activeElement?.id === TENANT_INPUT_ID || !validTenant,
                    [styles.is_invalid]: !validTenant,
                  })}
                >
                  <Input
                    id={TENANT_INPUT_ID}
                    name="company"
                    type="text"
                    placeholder="company"
                    value={validation.values.company}
                    onFocus={onFocusChange}
                    onChange={(e) => {
                      const val = e.target.value;
                      const lowerCaseNumbersOnly = /^[a-z0-9]+$/;
                      // If email is entered, but instance name is deleted, show error
                      if (!val && validation.values.email) {
                        validation.handleChange(e);
                        debouncedIsTenantAvailable.cancel();
                        validation.setFieldError(
                          "company",
                          "Please enter a valid instance name"
                        );
                        setIsValidTenant(false);
                        return;
                      }
                      if (val === "" || lowerCaseNumbersOnly.test(val)) {
                        validation.handleChange(e);
                        debouncedIsTenantAvailable(val);
                      }
                    }}
                    disabled={validation.isSubmitting}
                    style={{ marginLeft: "-0.75rem", paddingLeft: "0.75rem" }}
                  />
                  <div className="d-flex gap-md align-content-end align-items-end">
                    <div className="d-flex">
                      <div className="fw-bold text-black">
                        {validation.values.company.toLocaleLowerCase() || ""}
                      </div>
                      <div>{"." + FREE_USER_DOMAIN}</div>
                    </div>
                    <div className={styles.icon}>
                      {validTenant ? <TickSquareIcon /> : <InfoSquareIcon />}
                    </div>
                  </div>
                </label>
                {(activeElement?.id === TENANT_INPUT_ID || !validTenant) && (
                  <div
                    className={classNames(styles.form_feedback, {
                      [styles.is_error]: !validTenant,
                    })}
                  >
                    <span dangerouslySetInnerHTML={{ __html: tenantError }} />
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <Label htmlFor="company" className="form-label">
                How did you hear about us{" "}
                <span className="text-muted mb-2">(Optional)</span>
              </Label>
              <div className="d-flex flex-column">
                <Dropdown
                  id={HOW_DID_YOU_HEAR_ABOUT_US}
                  onChange={onChangeSelectSource}
                  value={signupSource}
                  options={signupSources}
                >
                  <div className={styles.select_source}>
                    <div
                      className={classNames(
                        styles.select_souce_text,
                        "text-muted"
                      )}
                    >
                      {signupSource === "" ? "Select an option" : signupSource}
                    </div>
                    <ArrowDownIcon />
                  </div>
                </Dropdown>
              </div>
            </div>

            <div className="mb-4">
              <div className="mb-1 form-check">
                <Input
                  defaultChecked
                  id="newsletter"
                  className="form-check-input"
                  type="checkbox"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={validation.isSubmitting}
                />
                <Label className="text-muted fw-400" htmlFor="newsletter">
                  Subscribe to our newsletter & stay updated
                </Label>
              </div>
              <p className="mb-0 fs-12 text-muted">
                <span>By creating an account, you agree to the </span>
                <Link
                  to="https://www.altimate.ai/terms"
                  target="_blank"
                  className="text-black fst-bold fw-medium"
                >
                  Terms & Conditions
                </Link>
                <span> and our </span>
                <Link
                  to="https://www.altimate.ai/privacy"
                  target="_blank"
                  className="text-black fst-bold fw-medium"
                >
                  Privacy Policy
                </Link>
              </p>
            </div>

            <div className="mt-4">
              <FormSubmit disabled={isFormDisabled()}>
                <span>Sign Up</span>
              </FormSubmit>
            </div>
          </Form>
        </div>
      )}
    </BaseContainer>
  );
};

export { FreeRegister };
