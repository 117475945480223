import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowRightIcon } from "@assets/icons";
import { forwardRef, useImperativeHandle, } from "react";
import { useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader, } from "reactstrap";
import IconButton from "../iconButton/IconButton";
import classes from "./styles.module.scss";
import { Button } from "../button/Button";
const Drawer = ({ buttonProps, buttonText, icon, title, onClose, children, onOpen, backdrop = true, offcanvasBodyClassName, }, ref) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleShow = () => {
        setShow(true);
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    };
    useImperativeHandle(ref, () => ({
        close() {
            setShow(false);
        },
        open() {
            setShow(true);
        },
    }));
    return (_jsxs(_Fragment, { children: [buttonText || icon ? (_jsx(Button, Object.assign({}, buttonProps, { onClick: handleShow, icon: icon, children: buttonText }))) : null, _jsxs(Offcanvas, { isOpen: show, onClosed: handleClose, toggle: handleClose, direction: "end", className: classes.offcanvas, backdrop: backdrop, unmountOnClose: true, children: [title ? (_jsx(OffcanvasHeader, { children: _jsx("h2", { children: title }) })) : null, _jsx(IconButton, { color: "primary", onClick: handleClose, className: classes.closeBtn, children: _jsx(ArrowRightIcon, {}) }), _jsx(OffcanvasBody, { className: offcanvasBodyClassName, children: children })] })] }));
};
export default forwardRef(Drawer);
