import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import text_logo from "../../assets/images/text_logo.svg";
import { useEffect } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { INTERCOM_APP_ID } from "../../config";
import classes from "./contactus.module.scss";
import { Link } from "react-router-dom";
const ContactUs = () => {
    if (INTERCOM_APP_ID) {
        return (_jsx(IntercomProvider, { appId: INTERCOM_APP_ID, autoBoot: true, children: _jsx(ContactUsIntercom, {}) }));
    }
    return null;
};
const ContactUsIntercom = () => {
    const { show } = useIntercom();
    useEffect(() => {
        const config = { attributes: false, childList: true, subtree: false };
        const callback = (mutations, obs) => {
            for (const mutation of mutations) {
                if (mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach((node) => {
                        if (node instanceof HTMLElement) {
                            if (node.id === "intercom-container") {
                                obs.disconnect();
                                show();
                            }
                        }
                    });
                }
            }
        };
        const observer = new MutationObserver(callback);
        observer.observe(document.body, config);
        return () => {
            observer.disconnect();
        };
    }, []);
    return (_jsxs("div", { className: classes.contactus, children: [_jsx("header", { children: _jsx("div", { className: classes.logo, children: _jsx(Link, { to: "/", children: _jsx("img", { src: text_logo, alt: "Altimate AI" }) }) }) }), _jsx("div", { className: classes.bg_img })] }));
};
export { ContactUs };
