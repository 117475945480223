var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useComponentsHelper from "@/modules/app/useComponentsHelper";
import { Card, CardBody, CardTitle, Stack } from "@/uiCore";
import { TeamMates, TaskLabels, TeamMateActionType, } from "@components";
import { useNavigate } from "react-router-dom";
import LearningsToggle from "./LearningsToggle";
import { postSendDatamateRequest } from "@/helpers/apis";
const TeammatesComponent = () => {
    const navigate = useNavigate();
    const { isComponentsApiInitialized } = useComponentsHelper();
    if (!isComponentsApiInitialized) {
        return null;
    }
    const onTeamMateSelect = (teammate, action) => __awaiter(void 0, void 0, void 0, function* () {
        switch (action) {
            case TeamMateActionType.REQUEST_ACCESS:
                const response = yield postSendDatamateRequest(teammate.key);
                return response === null || response === void 0 ? void 0 : response.ok;
            case TeamMateActionType.SEE_IN_ACTION:
                if (teammate.key === TaskLabels.ChartBot) {
                    navigate(`/summary?ref=${teammate.key}`);
                }
                break;
            case TeamMateActionType.VIEW_DETAILS:
                navigate(`/teammates/${teammate.key}`);
                break;
            default:
                break;
        }
    });
    return (_jsxs(Stack, { direction: "column", children: [_jsx("h1", { children: "Teammates" }), _jsx("p", { children: "We provide AI teammates that can help you with your work" }), _jsxs(Card, { children: [_jsxs(CardTitle, { className: "p-3 pb-0 d-flex gap-2", children: ["Teammate learning ", _jsx(LearningsToggle, {})] }), _jsx(CardBody, { children: _jsx("p", { children: "You can enable or disable teammate learnings here. If enabled, users can coach AI teammates through specific instructions in \u201CCoach AI\u201D marked product areas. Coached AI teammates can provide more custom and personalized experience to users." }) })] }), _jsx(TeamMates, { client: "SAAS", onSelect: onTeamMateSelect })] }));
};
export default TeammatesComponent;
