import { createSlice } from "@reduxjs/toolkit";
import { USD, USDSymbol } from "./constants";
import { getCurrencySymbol } from "@/uiCore";
export const initialState = {
    currency: USD,
    currencySymbol: USDSymbol,
    teammatesEnabled: false,
    loggedinUser: null,
    hasAcceptedCookies: false,
    sidebarExpanded: true,
    authHeaderSet: false,
};
const appSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setHasAcceptedCookies: (state, action) => {
            state.hasAcceptedCookies = action.payload;
        },
        setAuthHeaderSet: (state, action) => {
            state.authHeaderSet = action.payload;
        },
        setLoggedinUser: (state, action) => {
            state.loggedinUser = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
            state.currencySymbol = getCurrencySymbol(action.payload);
        },
        setSidebarExpanded: (state, action) => {
            state.sidebarExpanded = action.payload;
        },
        setTeammatesEnabled: (state, action) => {
            state.teammatesEnabled = action.payload;
        },
        setCurrencySymbol: (state, action) => {
            state.currencySymbol = action.payload;
        },
    },
});
export const { setCurrency, setCurrencySymbol, setTeammatesEnabled, setLoggedinUser, setHasAcceptedCookies, setSidebarExpanded, setAuthHeaderSet, } = appSlice.actions;
export default appSlice;
